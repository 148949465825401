import React, { type ReactElement, useState } from 'react';
import { LoadingButton } from '../widgets/LoadingButton/LoadingButton';
import { handleAxiosError } from '../http';
import { Alert } from 'react-bootstrap';
import { type ButtonProps } from 'react-bootstrap';
import { GenericModal } from '../GenericModal/GenericModal';

interface Props {
  title: string;
  open: boolean;
  onAccept: () => Promise<void> | void;
  onDecline: () => Promise<void> | void;
  prompt: string | ReactElement;
  positiveVariant?: ButtonProps['variant'];
  negativeVariant?: ButtonProps['variant'];
  positiveText?: string;
  negativeText?: string;
}

export function ConfirmationDialog({
  title,
  onAccept,
  onDecline,
  positiveVariant,
  negativeVariant,
  open,
  prompt,
  positiveText = 'Ok',
  negativeText = 'Cancel'
}: Props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const confirm = (func: () => Promise<void> | void) => async () => {
    try {
      setLoading(true);
      const result = func();
      result instanceof Promise && (await result);
    } catch (e) {
      setErrorMessage(handleAxiosError(e));
    }
    setLoading(false);
  };
  return (
    <GenericModal onExit={() => setErrorMessage('')} open={open} showClose={false} shrinkMode={true} label={title}>
      <Alert show={!!errorMessage} variant='danger'>
        {errorMessage}
      </Alert>
      <div>{prompt}</div>
      <div className='d-flex justify-content-end gap-2'>
        <LoadingButton loading={loading} variant={positiveVariant ?? 'primary'} onClick={confirm(onAccept)} label={positiveText} />
        <LoadingButton disabled={loading} loading={false} variant={negativeVariant ?? 'warning'} onClick={confirm(onDecline)} label={negativeText} />
      </div>
    </GenericModal>
  );
}
