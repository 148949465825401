import { Form } from 'react-bootstrap';
import type { ItemInventory } from '../Service.types';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import type { IncludedPartMap } from './QuoteForm';
import React from 'react';

const helper = createServerColumnHelper<ItemInventory>();
const ch = helper.accessor;

export function PartLibraryTableDef(includedParts: IncludedPartMap, setIncludedPartsData: React.Dispatch<React.SetStateAction<IncludedPartMap>>) {
  return [
    ch('ccn', 'string', {
      header: 'CCN',
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),
    ch('partName', 'string', {
      header: 'Part Name',
      enableColumnFilter: true,
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id
    }),

    ch('lastUsedOn', 'date', {
      header: 'Last Used Date',
      enableColumnFilter: true,
      meta: { dataType: 'date' },
      cell: (info) => {
        const date = info.getValue() as Date;
        return date != null ? date.toLocaleDateString() : 'N/A';
      },
      sortDescFirst: true,
      footer: (props) => props.column.id
    }),
    ch('altPart', 'string', {
      header: 'Alternative Parts',
      meta: { dataType: 'string' },
      enableColumnFilter: true,
      cell: (info) => info.getValue() ?? 'None',
      footer: (props) => props.column.id
    }),
    helper.display({
      id: 'Qty',
      header: 'Qty',
      cell: (info) => (
        <div>
          <Form.Control
            type='number'
            style={{ width: '100%' }}
            placeholder={'0'}
            value={includedParts.get(info.row.original.ccn)?.selectedQuantity ? includedParts.get(info.row.original.ccn)?.selectedQuantity : ''}
            onChange={(event) => {
              const part = info.row.original;
              if (parseInt(event.target.value) < 0) {
                return;
              }
              if (parseInt(event.target.value) === 0 || event.target.value === '') {
                setIncludedPartsData((prevParts) => {
                  return prevParts.remove(part.ccn as string);
                });
              } else {
                setIncludedPartsData((prevParts) => {
                  return prevParts.set(part.ccn, { part, selectedQuantity: parseInt(event.target.value) });
                });
              }
            }}
          />
        </div>
      ),
      footer: (props) => props.column.id
    })
  ];
}
