import { MakeFieldRow } from '../RmxServiceActivityDetails/field-table';
import type { Quote } from '../Service.types';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import type { ModalState } from '../../hooks/useModal';
import { RmxServiceDetails } from '../RmxServiceActivityDetails/RmxServiceDetails';
import { formatCurrency } from '../../utils';

type Props = {
  state: ModalState<Quote>;
};

const QuoteFieldRow = MakeFieldRow<Quote>();

export function QuoteDetailModals({ state }: Props) {
  const quote = state.param;
  return (
    <GenericModal shrinkMode={true} state={state} label={'Quote Details'} showClose={true} showHeader={true}>
      {quote !== undefined ? (
        <RmxServiceDetails>
          <QuoteFieldRow header={'Quote Id'} name={'quoteCrmId'} value={quote.quoteCrmId} />
          <QuoteFieldRow header={'Quote Date'} name={'createdAt'} value={quote.createdAt} />
          <QuoteFieldRow header={'Quote Status'} name={'status'} value={quote.status} />
          <QuoteFieldRow header={'Quote Type'} name={'type'} value={quote.type} />
          <QuoteFieldRow header={'Quote Total'} name={'quoteTotal'} value={formatCurrency(quote.quoteTotal)} />
          <QuoteFieldRow header={'Quote Description'} name={'description'} value={quote.description === '' ? 'N/A' : quote.description} />
          <QuoteFieldRow
            header={'Quote Parts'}
            name={'includedParts'}
            display={(value) => {
              return value === null || value === undefined ? (
                'N/A'
              ) : (
                <div>
                  {value.map((part, index) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                      {`CCN: ${part.part.ccn} , Part Quantity: ${part.selectedQuantity}, Part Price: ${formatCurrency(part.part.price)}`}
                    </div>
                  ))}
                </div>
              );
            }}
            value={quote.includedParts}
          />
        </RmxServiceDetails>
      ) : null}
    </GenericModal>
  );
}
