import { DataTable } from '../../shared-compat/DataTable/DataTable';
import React, { type ReactElement, type Ref, useMemo, useState } from 'react';
import { useRmxServiceApi } from '../api';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { myPartColumnsDef } from '../RmxServiceTableDefinitions/MyPartTableDefinition';
import DarkSelect from '../../shared-compat/utils/DarkSelect/DarkSelect';

export function useMyPartsTable() {
  const api = useRmxServiceApi();
  const [myPartDropDownValue, setMyPartDropDownValue] = useState('Tote');
  const myPartColumns = useMemo(() => myPartColumnsDef({ dropDownValue: myPartDropDownValue }), [myPartDropDownValue]);
  const myPartsTable = useServerDataTable({
    queryKey: ['my-parts', myPartDropDownValue],
    // enabled: activeTab === 'myParts',
    columns: myPartColumns,
    getRows: async (params) => {
      return await api.myParts({
        ...params,
        getSurplus: myPartDropDownValue === 'Surplus',
        getWip: myPartDropDownValue === 'WIP',
        getInventory: myPartDropDownValue === 'Inventory'
      });
    }
  });

  return { myPartsTable, myPartDropDownValue, setMyPartDropDownValue };
}

interface MyPartsDataTable {
  header?: ReactElement;
  dropDownGuideRef: Ref<HTMLSelectElement>;
}
export function MyPartsTable(props: MyPartsDataTable) {
  const { myPartsTable, myPartDropDownValue, setMyPartDropDownValue } = useMyPartsTable();

  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={myPartsTable}
        header={
          // This header is included because it messes with the server data tables state.
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DarkSelect
              innerRef={props.dropDownGuideRef}
              onChange={(value) => {
                setMyPartDropDownValue(value);
              }}
              options={['Tote', 'Surplus', 'WIP', 'Inventory']}
              value={myPartDropDownValue}
            />
            {props.header ?? undefined}
          </div>
        }
      />
    </>
  );
}
