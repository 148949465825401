import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { ReactTable, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import React, { useMemo } from 'react';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { type ItemInventory } from '../Service.types';
import { Button, Card, Form } from 'react-bootstrap';
import { useRmxServiceApi } from '../api';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PartLibraryTableDef } from './PartLibraryTableDefinition';
import s from './RmxServicePartShop.module.scss';
import type { IncludedPartMap } from './QuoteForm';
import { getMapValues } from '../../../common/util';
import type { ModalState } from '../../hooks/useModal';

interface Props {
  state: ModalState<undefined>;
  includedParts: IncludedPartMap;
  partHistory: boolean;
  assetId: number | null;
  activityId: string | null;
  setIncludedPartsData: React.Dispatch<React.SetStateAction<IncludedPartMap>>;
}

export function RmxServicePartShop({ includedParts, assetId, partHistory, setIncludedPartsData, state }: Props) {
  const api = useRmxServiceApi();

  const columns = useMemo(() => PartLibraryTableDef(includedParts, setIncludedPartsData), [includedParts, setIncludedPartsData]);

  const partLibraryTable = useServerDataTable({
    showPageSizeSelector: false,
    initialPageSize: 5,
    queryKey: [partHistory ? 'part-history' : 'parts-library', assetId],
    columns: columns,
    enabled: state.isOpen,
    getRows: async (params) => {
      return await api.getPartLibrary({ ...params, assetId: assetId, daysFilter: null, partHistory });
    },
    onlyShowIfFiltering: !partHistory,
    initialSorting: [{ id: 'ccn', desc: false }]
  });

  const shoppingCartColumns = [
    { header: 'CCN', accessorKey: 'ccn' },
    { header: 'Part Name', accessorKey: 'partName' },
    {
      header: 'Qty',
      accessorKey: 'selectedQuantity',
      valueFormatter: (_, row) => {
        const part = row as unknown as ItemInventory;
        return (
          <div style={{ display: 'flex' }}>
            <Form.Control
              type='number'
              style={{ width: '80%' }}
              placeholder={'0'}
              value={includedParts.get(part.ccn)?.selectedQuantity ? includedParts.get(part.ccn)?.selectedQuantity : ''}
              onChange={(event) => {
                setIncludedPartsData((prevParts) => {
                  return prevParts.set(part.ccn, { part, selectedQuantity: parseInt(event.target.value) });
                });
              }}
            />
          </div>
        );
      }
    },
    {
      header: 'Action',
      accessorKey: 'Action',
      valueFormatter: (_, row) => {
        return (
          <Button
            variant='danger'
            size='sm'
            onClick={() => {
              // remove part from shopping cart by filtering out the part with the same ccn
              setIncludedPartsData((prevParts) => {
                return prevParts.remove(row.ccn as string);
              });
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        );
      }
    }
  ] satisfies SimpleColumnDef[];

  return (
    <GenericModal
      className={s['part-modal']}
      dialogClassName={s['part-modal-dialog']}
      contentClassName={s['part-modal-content']}
      bodyClassName='d-flex flex-column align overflow-hidden p-0'
      label=' '
      showClose={true}
      state={state}
    >
      {
        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <i className='fa fa-shopping-cart' aria-hidden='true' style={{ marginRight: '10px' }} />
              <h4 style={{ margin: 0, color: 'white' }}>Shopping Cart</h4>
            </div>

            <div style={{ minHeight: '200px', maxHeight: '200px', overflowY: 'auto' }}>
              {includedParts.size > 0 ? (
                <ReactTable includeHeaders={true} columns={shoppingCartColumns} data={getMapValues(includedParts).map((p) => p.part)} />
              ) : (
                <Card>
                  <Card.Body>Include parts from the library to add to your shopping cart</Card.Body>
                </Card>
              )}
            </div>
          </div>

          {/* Horizontal Separator */}
          <div style={{ height: '1px', backgroundColor: '#ccc', margin: '20px 0' }} />
          <div style={{ flex: 1, overflowX: 'hidden' }}>
            <Card>
              <Card.Body>Use the search filters to find the parts you need. Click on the checkbox to include the part in your shopping cart.</Card.Body>
            </Card>
            <DataTable table={partLibraryTable} enableExport={false} />
          </div>
        </div>
      }
    </GenericModal>
  );
}
