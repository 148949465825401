import {
  type Column,
  type ColumnDef,
  type ColumnFiltersState,
  createColumnHelper,
  type FiltersColumn,
  type PaginationState,
  type SortingState,
  type Table
} from '@tanstack/react-table';
import { type ColumnFilter } from '@tanstack/table-core/src/features/Filters';
import { type ColumnSort } from '@tanstack/table-core/src/features/Sorting';
import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from '../../utils/http';
import { findColumnByIdentifier } from './DataTable';
import { type ColumnDataType, type DataTableColumnFilters, type ServerColumnFilter } from './filter-types';
import { formatDateOnlyIso, numberOrNull } from '../../utils';
import { parseTime } from '../../utils/timeOnly';
import { type DisplayColumnDef, type IdentifiedColumnDef } from '@tanstack/table-core/build/lib/types';
import type { Nominal } from '../../utils/typescript-utils';
import { getColumnName } from './utils';

type SetState<T> = Dispatch<SetStateAction<T>>;
export type BetterColumnSort<T> = {
  desc: boolean;
  id: keyof T;
};
export type BaseDataTableHookParams<T, TColumnDef> = {
  initialPageSize?: number;
  readonly columns: readonly TColumnDef[];
  initialSorting?: BetterColumnSort<T>[];
  globalFilter?: string;
  // if this is passed, don't pass the globalFilter prop
  enableGlobalSearchBar?: boolean;
  showPageSizeSelector?: boolean;
  enableColumnFilters?: boolean;
  enableExport?: boolean;
  enableLogging?: boolean;
};

function mapServerFilter<T>([f, columnDef]: [ColumnFilter, ColumnDef<T, any>]) {
  const dataType = columnDef.meta!.dataType;
  const columnName = getColumnName(columnDef);
  const serverFilterDefs = columnDef.meta?.serverFilters ?? [];

  if ((serverFilterDefs.includes('exact-date') || dataType === 'date') && f.value instanceof Date) {
    return { id: columnName, dateOnly: { value: formatDateOnlyIso(f.value) } };
  }
  if ((serverFilterDefs.includes('exact-time') || dataType === 'time') && typeof f.value === 'string') {
    return { id: columnName, text: { value: parseTime(f.value) } };
  }
  if (serverFilterDefs.includes('exact-text') && typeof f.value === 'string') {
    return { id: columnName, text: { value: f.value ?? null } };
  } else if (dataType === 'number' && typeof f.value === 'string') {
    // eslint-disable-next-line id-denylist
    return { id: columnName, number: { value: numberOrNull(f.value) } };
  } else if (typeof f.value === 'string') {
    return { id: columnName, text: { containsValue: f.value ?? null } };
  } else if (Array.isArray(f.value) && (typeof f.value[0] === 'number' || typeof f.value[1] === 'number')) {
    const minMax = f.value as number[];
    // eslint-disable-next-line id-denylist
    return { id: columnName, number: { min: minMax[0] ?? null, max: minMax[1] ?? null } };
  } else {
    throw new Error('unsupported column filter: ' + JSON.stringify(f));
  }
}

function useBaseDataTable<T>(params: BaseDataTableHookParams<T, any>) {
  const [globalFilterState, setGlobalFilter] = useState<string>('');
  const globalFilter = params.globalFilter ?? globalFilterState;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>((params.initialSorting as ColumnSort[]) ?? []);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: params.initialPageSize ?? 10, pageIndex: 0 });
  return {
    ...params,
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination
  } as const satisfies BaseTableData<any>;
}

type BaseTableData<T> = {
  columnFilters: ColumnFilter[];
  setColumnFilters: SetState<ColumnFilter[]>;
  pagination: PaginationState;
  setPagination: SetState<PaginationState>;
  sorting: ColumnSort[];
  setSorting: SetState<ColumnSort[]>;
  globalFilter: string;
  setGlobalFilter: SetState<string>;
  enableLogging?: boolean;
} & BaseDataTableHookParams<T, ColumnDef<T, any>>;

export type ClientDataTableHookParams<T> = {
  data: T[];
} & BaseDataTableHookParams<T, ColumnDef<T, any>>;

export function useClientDataTable<T>(params: ClientDataTableHookParams<T>) {
  const baseTable = useBaseDataTable(params);
  return {
    ...baseTable,
    data: params.data,
    total: params.data.length,
    isError: false,
    isFetching: false,
    // tslint:disable-next-line:no-empty
    refetch: () => {},
    columns: params.columns,
    rowModel: 'Client'
  } as const satisfies TableState<T>;
}

export type ServerDataTableHookParams<T> = {
  onlyShowIfFiltering?: boolean; // if true, the table will only show data on when column filtering
  enabled?: boolean;
  getRows: (p: DataTableRequest) => Promise<DataTableResponse<T>>;
  queryKey: unknown[];
} & BaseDataTableHookParams<T, ServerColumnDef<T, any>>;

type ServerColumnDef<T, TValue> = Nominal<
  Omit<ColumnDef<T, any>, 'sortingFn'> | { accessorKey: (string & {}) | keyof T; meta: { dataType: ColumnDataType<TValue> } },
  'ServerColumnDefinition'
>;
export type DataTableRequest = {
  globalFilter?: string;
  columnFilters: DataTableColumnFilters;
  sorting: ColumnSort[];
  limit: number;
  skip: number;
  getOptions?: boolean;
  optionsColumnId?: string;
};

export type DataTableResponse<T> = {
  data: T[];
  options: unknown[];
  count: number;
};

/**
 * This is a hook that memoizes an array. It is useful for preventing unnecessary rerenders when the contents of the array is the same
 * @param arr
 */
function useMemoizeArray<TArray extends unknown[]>(arr: TArray) {
  const ref = useRef(arr);
  if (!arr.every((v, i) => v === ref.current[i])) {
    ref.current = arr;
  }
  return ref.current;
}

/**
 * This is a helper function that allows you to create a column definition for a server table. It restricts the type
 */
export function createServerColumnHelper<TData>() {
  const helper = createColumnHelper<TData>();
  return {
    accessor: <TAccessor extends keyof TData & string, TValue extends TData[TAccessor]>(
      accessor: TAccessor,
      dataType: ColumnDataType<TData[TAccessor]>,
      column: IdentifiedColumnDef<TData, TValue> & Partial<Pick<FiltersColumn<TValue>, 'setFilterValue'>>
    ) => {
      const result = helper.accessor(accessor as any, column);
      if (!result.meta) result.meta = {};
      result.meta.dataType = dataType as ColumnDataType<unknown>;
      return result as ServerColumnDef<TData, TValue>;
    },
    display: helper.display as (column: DisplayColumnDef<TData>) => ServerColumnDef<TData, unknown>
  };
}
export function useServerDataTable<T>(params: ServerDataTableHookParams<T>) {
  const { getRows, columns, enabled, ...baseProps } = params;
  const queryKey = useMemoizeArray(params.queryKey);
  const baseDataTable = useBaseDataTable({
    columns,
    ...baseProps,
    enableColumnFilters: baseProps.enableColumnFilters ?? true,
    showPageSizeSelector: baseProps.showPageSizeSelector ?? true,
    enableGlobalSearchBar: baseProps.enableGlobalSearchBar ?? false
  });
  const { sorting, globalFilter, columnFilters, pagination } = baseDataTable;
  const { pageIndex, pageSize } = pagination;
  const getRowsRef = useRef(getRows);
  getRowsRef.current = getRows;

  const serverColumnFilters = useMemo(
    () =>
      columnFilters
        .filter((f) => !!f.value)
        .map((f) => [f, findColumnByIdentifier(columns as unknown as ColumnDef<T, any>[], f.id)!] as const)
        .filter(([_, columnDef]) => Boolean(columnDef.meta?.dataType))
        .map<ServerColumnFilter>(([f, columnDef]) => {
          return mapServerFilter([f, columnDef]);
        }),
    [columnFilters, columns]
  );

  const serializedColumnFilters = useMemo(() => JSON.stringify(serverColumnFilters), [serverColumnFilters]);
  const { setPagination } = baseDataTable;
  useEffect(() => {
    setPagination((p) => ({ ...p, pageIndex: 0 }));
  }, [serializedColumnFilters, setPagination]);
  const reactQueryEnabled = params.onlyShowIfFiltering ? serverColumnFilters.length > 0 : true;
  const {
    data: serverData,
    isError,
    isFetching,
    refetch,
    error
  } = useQuery({
    queryKey: [...queryKey, pageIndex, pageSize, sorting, serverColumnFilters, globalFilter],
    queryFn: () => {
      return getRows({
        globalFilter,
        columnFilters: serverColumnFilters,
        sorting,
        skip: pageIndex * pageSize,
        limit: pageSize
      });
    },
    enabled: enabled === false ? false : reactQueryEnabled,
    refetchOnWindowFocus: true,
    // This is a hack to prevent the placeholderData from being used when the onlyShowIfFiltering is true and there are no filters
    placeholderData: (previousData) =>
      // only show the placeholder data if the onlyShowIfFiltering is false and if there are filters
      params.onlyShowIfFiltering && serverColumnFilters.length === 0 ? undefined : previousData
  });

  // console.log('serverData', serverData);
  if (isAxiosError(error)) {
    console.error(error);
  } else if (error) {
    throw error;
  }

  const getOptions = useMemo(
    () => async (columnId: string) => {
      return (
        await getRowsRef.current({
          columnFilters: serverColumnFilters,
          sorting: [],
          skip: 0,
          limit: 0,
          getOptions: true,
          optionsColumnId: columnId
        })
      ).options!;
    },
    [serverColumnFilters]
  );

  return {
    ...baseDataTable,
    data: serverData?.data,
    total: serverData?.count ?? 0,
    isError,
    isFetching,
    refetch,
    columns: columns as unknown as ColumnDef<T, any>[],
    rowModel: 'Server',
    getOptions,
    baseQueryKey: queryKey
  } as const satisfies TableState<T>;
}

export type TableState<T> = BaseTableData<T> & {
  data: T[] | undefined;
  total: number;
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
  rowModel: RowModel;
  getOptions?: (columnId: string) => Promise<unknown[]>;
  baseQueryKey?: unknown[];
};

export type RowModel = 'Client' | 'Server';

export function useServerOptions<T>(params: { column: Column<any, any>; table: Table<any>; tableState: TableState<T>; initialized: boolean }) {
  const {
    column,
    tableState: { getOptions, baseQueryKey, columnFilters },
    initialized
  } = params;

  const columnName = getColumnName(column.columnDef);
  const usingServerOptions = !!(baseQueryKey && getOptions);
  const shouldQuery = initialized && usingServerOptions;
  const getOptionsFn = useCallback(async () => (await getOptions!(columnName)) as string[], [columnName, getOptions]);
  const {
    data: serverOptions,
    error,
    isLoading,
    refetch
  } = useQuery({
    queryKey: [...(baseQueryKey ?? []), 'column-options', columnName, columnFilters],
    queryFn: getOptionsFn,
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    // really important to prevent the data list from remounting on every reload
    placeholderData: (previousData) => previousData
  });

  const optionsCount = usingServerOptions ? serverOptions?.length : column.getFacetedUniqueValues().size;

  const facetedUniqueValues = column.getFacetedUniqueValues();

  const sortedUniqueValues = useMemo(
    () => (usingServerOptions ? serverOptions ?? [] : (Array.from(facetedUniqueValues.keys()).sort() as string[])),
    [facetedUniqueValues, serverOptions, usingServerOptions]
  );

  return {
    serverOptions,
    error,
    isLoading,
    optionsCount,
    sortedUniqueValues,
    refetch,
    usingServerOptions
  };
}
