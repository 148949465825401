import { DataTable } from '../../shared-compat/DataTable/DataTable';
import React, { useMemo, useState } from 'react';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { quoteColumnsDef } from '../RmxServiceTableDefinitions/QuoteTableDefinition';
import { useRmxServiceApi } from '../api';
import { useModal } from '../../hooks/useModal';
import { QuoteDetailModals } from '../RmxServiceQuotes/QuoteDetailModals';
import type { Quote } from '../Service.types';
import { DayFilter } from '../../utils/DayFilter/DayFilter';

export function useQuoteTable(filterByAssetId?: number) {
  const api = useRmxServiceApi();
  const [quotesDateFilter, setQuotesDateFilter] = useState<number | null | string>(null);

  const quotesColumns = useMemo(() => quoteColumnsDef?.(!filterByAssetId), [filterByAssetId]);

  const quotesTable = useServerDataTable({
    queryKey: ['quotes'],
    columns: quotesColumns,
    getRows: async (params) => {
      return await api.getQuotes({ ...params, assetId: filterByAssetId ?? null });
    },
    initialSorting: [
      { id: 'status', desc: false },
      { id: 'createdAt', desc: true }
    ]
  });

  const quotesModal = useModal<Quote>();
  return { quotesTable, quotesModal, quotesDateFilter, setQuotesDateFilter };
}

interface QuoteDataTable {
  filterByAssetId?: number;
}

export function QuoteTable(props: QuoteDataTable) {
  const { quotesTable, quotesModal, quotesDateFilter, setQuotesDateFilter } = useQuoteTable(props.filterByAssetId);
  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={quotesTable}
        onRowClick={async (row) => {
          await quotesModal.open(row);
        }}
        footer={<DayFilter value={quotesDateFilter} setGlobalDateFilter={setQuotesDateFilter} />}
      />
      <QuoteDetailModals state={quotesModal} />
    </>
  );
}
