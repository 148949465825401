import type { ModalState } from 'src/portal/hooks/useModal';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { TimeStats } from './TimeStats';

export interface Props {
  state: ModalState<unknown, unknown>;
}
export function TimeStatsModal({ state }: Props) {
  return (
    <GenericModal label={'Time Stats'} showClose={true} state={state}>
      <TimeStats />
    </GenericModal>
  );
}
