import React, { type ReactElement, useMemo, useState } from 'react';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import { Button } from 'react-bootstrap';
import { DayFilter } from '../../utils/DayFilter/DayFilter';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { flagsColumnsDef } from '../RmxServiceTableDefinitions/FlagTableDefinition';
import { useRmxServiceApi } from '../api';
import { useModal } from '../../hooks/useModal';
import type { Flag } from '../Service.types';
import { RmxCreateFlag } from '../Flags/RmxCreateFlag';
import { RmxViewFlag } from '../Flags/RmxViewFlag';

export function useFlagTable(activityId?: string) {
  const api = useRmxServiceApi();

  const flagsColumns = useMemo(() => flagsColumnsDef(), []);
  const [flagsDateFilter, setFlagsDateFilter] = useState<number | null | string>(null);

  const flagsTable = useServerDataTable({
    queryKey: ['flags', flagsDateFilter],
    columns: flagsColumns,
    getRows: async (params) => {
      return await api.getFlags({ ...params, activityId: activityId ?? null, daysFilter: flagsDateFilter });
    },
    initialSorting: [
      { id: 'status', desc: false },
      { id: 'createdAt', desc: true }
    ]
  });

  return { flagsTable, flagsDateFilter, setFlagsDateFilter };
}

interface FlagDataTable {
  header?: ReactElement;
  activityId?: string;
  hideNewFlagButton?: boolean;
  enabled?: boolean;
}

export function MyFlagTable(props: FlagDataTable) {
  const { flagsTable, flagsDateFilter, setFlagsDateFilter } = useFlagTable(props.activityId);
  const createFlagModal = useModal<{ activityId?: string; customerId?: number }>();
  const flagViewModal = useModal<Flag>();
  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={flagsTable}
        header={
          <>
            {props.hideNewFlagButton === true ? null : (
              <Button onClick={() => createFlagModal.setIsOpen(true)} variant={'secondary'}>
                New Flag
              </Button>
            )}
            {props.header}
          </>
        }
        onRowClick={async (row) => {
          void flagViewModal.open(row);
        }}
        footer={<DayFilter value={flagsDateFilter} setGlobalDateFilter={setFlagsDateFilter} />}
      />
      {createFlagModal.isOpen && <RmxCreateFlag refetchFlagTable={flagsTable.refetch} state={createFlagModal} />}
      {flagViewModal.isOpen && <RmxViewFlag refetchFlagTable={flagsTable.refetch} state={flagViewModal} />}
    </>
  );
}
