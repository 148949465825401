import React, { type ReactElement, useMemo, useRef, useState } from 'react';
import { workColumnsDef } from '../RmxServiceTableDefinitions/MyWorkTableDefinition';
import { useOnboardingContext } from '../../onboardings/OnboardingContext';
import { MyWorkPartStatusOnboardingGuide, type PartStatusColumnHeaderRefType } from '../../onboardings/MyWorkPartStatusOnboardingGuide';
import { useRmxServiceApi } from '../api';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { useModal } from '../../hooks/useModal';
import { RmxServiceActivityModal, type RmxServiceActivityModalParam } from '../RmxServiceTabs/RmxServiceActivityModal';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import type { Activity } from '../Service.types';
import { redirectToAsset } from '../RmxServiceTabs/redirectToAsset';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { QuoteTable } from '../QuoteTable/QuoteTable';
import { ActivityTable } from '../ActivityTable/ActivityTable';
import { MyFlagTable } from '../MyFlagTable/MyFlagTable';
import { DayFilter } from '../../utils/DayFilter/DayFilter';
import { RmxCreateFlag } from '../Flags/RmxCreateFlag';

export function useMyWorkTable(assetId?: number) {
  const api = useRmxServiceApi();

  const [displayPartStatusOnboarding, setDisplayPartStatusOnboarding] = useState<boolean>(false);
  const [workDateFilter, setWorkDateFilter] = useState<number | null | string>(null);

  const { onBoardingTitleValueMap } = useOnboardingContext();

  const partStatusUserOnboardingInfo = onBoardingTitleValueMap?.get('MyWorkPartStatus');
  const partStatusColumnHeaderRef = useRef<HTMLDivElement>(null) satisfies PartStatusColumnHeaderRefType;

  const workColumns = useMemo(
    () =>
      workColumnsDef(
        () => {
          setDisplayPartStatusOnboarding(true);
        },
        partStatusUserOnboardingInfo?.isActive ?? false,
        partStatusColumnHeaderRef
      ),
    [partStatusUserOnboardingInfo?.isActive, partStatusColumnHeaderRef]
  );
  const myWorkTable = useServerDataTable({
    columns: workColumns,
    queryKey: ['my-work', workDateFilter],
    getRows: async (params) => {
      return await api.getMyActivities({ ...params, assetId: assetId ?? null, daysFilter: workDateFilter, myWork: true, statusFilter: null });
    },
    initialSorting: [
      // { id: 'partStatus', desc: true }, // was showing unpicked before ready and affecting the order
      { id: 'startDate', desc: false },
      { id: 'onSiteTime', desc: false }
    ]
  });

  const activityModal = useModal<RmxServiceActivityModalParam>({
    onClose: async () => {
      await myWorkTable!.refetch();
    }
  });

  return {
    myWorkTable,
    activityModal,
    displayPartStatusOnboarding,
    setDisplayPartStatusOnboarding,
    partStatusColumnHeaderRef,
    workDateFilter,
    setWorkDateFilter
  };
}

interface MyWorkDataTable {
  header?: ReactElement;
  hideEllipsisButton?: boolean;
  assetId?: number;
}

export function MyWorkTable(props: MyWorkDataTable) {
  const {
    myWorkTable,
    activityModal,
    displayPartStatusOnboarding,
    setDisplayPartStatusOnboarding,
    partStatusColumnHeaderRef,
    setWorkDateFilter,
    workDateFilter
  } = useMyWorkTable(props.assetId);
  const assetQuoteHistoryModal = useModal<{ assetId: number | undefined }>();
  const assetHistoryModal = useModal<{ assetId: number | undefined }>();
  const flagHistoryModal = useModal<{ activityId: string }>();
  const createFlagModal = useModal<{ activityId?: string; customerId?: number; siteName?: string }>();

  return (
    <>
      <MyWorkPartStatusOnboardingGuide
        onExit={() => setDisplayPartStatusOnboarding(false)}
        partStatusColumnHeaderRef={partStatusColumnHeaderRef}
        forceDisplay={displayPartStatusOnboarding}
      />
      <DataTable
        negativeMarginsOnMobile={true}
        table={myWorkTable}
        onRowClick={(row: Activity) => {
          void activityModal.open({
            resource: row,
            disableEditingActivityDetails: true, // no editing of activity details occurs in My Work
            enableTechEditing: true
          });
        }}
        header={props.header ?? undefined}
        footer={<DayFilter value={workDateFilter} setGlobalDateFilter={setWorkDateFilter} />}
      />
      {activityModal.isOpen && (
        <RmxServiceActivityModal
          state={activityModal}
          enableTechEditing={true}
          createFlag={(a) => createFlagModal.open({ activityId: a.id, customerId: a.customerId })}
          openAsset={async (a) => await redirectToAsset(a.assetId, a.assetName)}
          openAssetHistory={async (a) => await assetHistoryModal.open({ assetId: a.assetId })}
          openQuoteHistory={async (a) => await assetQuoteHistoryModal.open({ assetId: a.assetId })}
          openFlagHistory={async (a) => await flagHistoryModal.open({ activityId: a.id })}
          hideEllipsisButton={props.hideEllipsisButton}
        />
      )}

      <RmxCreateFlag state={createFlagModal} />
      <GenericModal state={assetQuoteHistoryModal} fullScreen={true} label={`Quote History`} showClose={true}>
        <QuoteTable filterByAssetId={assetQuoteHistoryModal.param?.assetId} />
      </GenericModal>
      <GenericModal state={assetHistoryModal} label={`Asset History`} fullScreen={true} showClose={true}>
        <ActivityTable isMyWork={false} hideEllipsisButton={true} filerByAssetId={assetHistoryModal.param?.assetId} />
      </GenericModal>

      <GenericModal fullScreen={true} state={flagHistoryModal} label={'Flag History'} showClose={true}>
        <MyFlagTable activityId={flagHistoryModal.param?.activityId} hideNewFlagButton={true} />
      </GenericModal>
    </>
  );
}
