import { type RefObject } from 'react';
import { OnboardingGuideComponent } from './OnboardingGuideComponent';
import { useOnboardingGuide } from './OnboardingContext';

export type PartStatusColumnHeaderRefType = RefObject<HTMLDivElement>;

interface Props {
  onExit: () => void;
  partStatusColumnHeaderRef: PartStatusColumnHeaderRefType;
  forceDisplay: boolean;
}

export function MyWorkPartStatusOnboardingGuide({ forceDisplay, onExit, partStatusColumnHeaderRef: element }: Props) {
  const { guide, addGuideInteraction } = useOnboardingGuide('MyWorkPartStatus', forceDisplay);
  if (!guide) {
    return null;
  }
  return (
    <OnboardingGuideComponent
      guide={guide}
      addGuideInteraction={addGuideInteraction}
      onExit={onExit}
      steps={[
        {
          element: element.current,
          title: '<u>Part Status Column</u>',
          intro: '<strong>There are 3 different statuses</strong> <br><br> Each represents the current state of the parts on an Activity.'
        },
        {
          element: element.current,
          title: '<u>Part Status Column</u>',
          intro: '<strong><code>Ready</code> status </strong> <br><br> This means all parts have been picked.'
        },
        {
          element: element.current,
          title: '<u>Part Status Column</u>',
          intro: `
          <strong><code>Unpicked</code> status</strong>
          <br><br>
          This means the activity has unpicked parts.
          <br><br>
          <em>This status has a date after it to signify when all the parts should have arrived by.
          <br/><br/>
          ex. <code>Unpicked</code> - 03/05/2024</em>
          `
        },
        {
          element: element.current,
          title: '<u>Part Status Column</u>',
          intro: '<strong><code>Blank</code> status</strong> <br></br> This means the activity has no parts.'
        }
      ]}
    />
  );
}
