import React from 'react';
import { type Column, type Row, type Table } from '@tanstack/react-table';
import { type RowData } from '@tanstack/table-core/src/types';

export type CellData = null | string | string[] | number | boolean;
export type RowOfData = CellData[];

export function getFirstDefined<T>(...args: (T | undefined)[]) {
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== 'undefined') {
      return args[i];
    }
  }
  return undefined as T;
}

// To get column name while exporting
const defaultGetColumnExportName = (col: Column<any>) => {
  let name = col.columnDef.header;
  if (typeof name === 'object' || typeof name === 'function') {
    name = col.id;
  }
  return name;
};

// To get cell value while exporting
const defaultGetCellExportValue = <T>(row: Row<T>, col: Column<T>) => {
  return row.getValue(col.id) as CellData;
};

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    canExport?: boolean;
    exportValue?: any;
    disableExport?: boolean;
    exportName?: string;
    isVisible?: boolean;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Table<TData extends RowData> {
    disableExport?: boolean;
  }
}

export function useExportData<T>(instance: Table<T>) {
  const { disableExport } = instance;
  const rows = instance.getRowModel().rows;
  const allColumns = instance.getAllColumns();

  // Adding `canExport` & `exportValue` meta data
  allColumns.forEach((column) => {
    const canExport = getFirstDefined(column.columnDef.meta?.disableExport === true ? false : undefined, disableExport === true ? false : undefined, true);
    column.columnDef.meta ??= {};
    column.columnDef.meta.canExport = canExport;
    column.columnDef.meta.exportValue = column.columnDef.meta?.exportName ?? defaultGetColumnExportName(column);
  });

  // This method will enable export of data on `instance` object
  return React.useCallback(
    async (fileType: ExportFileType, exportFileName: string) => {
      const all = true;
      // Columns which are exportable
      const exportableColumns = allColumns.filter((col) => col.columnDef.meta?.canExport && (all || col.columnDef.meta?.isVisible));

      if (exportableColumns.length === 0) {
        console.warn('No exportable columns are available');
      }

      // Rows which are exportable
      const exportableRows = rows.map((row) => {
        return exportableColumns.map((col) => {
          // const { getCellExportValue = defaultGetCellExportValue } = col;
          return defaultGetCellExportValue(row, col);
        });
      });

      // Getting fileName
      const fileName = exportFileName;

      // Get `FileBlob` to download
      const fileBlob = await getExportFileBlob({
        columns: exportableColumns,
        data: exportableRows,
        fileName,
        fileType
      });

      // Trigger download in browser
      if (fileBlob) {
        downloadFileViaBlob(fileBlob, fileName, fileType);
      }
    },
    [rows, allColumns]
  );
}

function downloadFileViaBlob(fileBlob: Blob, fileName: string, type: string) {
  if (fileBlob) {
    const dataUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.download = `${fileName}.${type}`;
    link.href = dataUrl;
    link.click();
  }
}

type ExportFileType = 'csv' | 'xlsx' | 'pdf';

type BlobExportOptions<T> = {
  columns: Column<T, unknown>[];
  fileType: ExportFileType;
  data: RowOfData[];
  fileName?: string;
};

async function getExportFileBlob({ columns, data, fileType, fileName }: BlobExportOptions<any>) {
  const { Papa, XLSX, JsPDF, autoTable } = await import('./lazy-export-libs');
  if (fileType === 'csv') {
    // CSV example
    const headerNames = columns.map((col) => col.columnDef.meta?.exportValue as string);

    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: 'text/csv' });
  } else if (fileType === 'xlsx') {
    // XLSX example

    const header = columns.map((c) => c.columnDef.meta?.exportValue as string);
    const compatibleData = data.map((row) => {
      const obj: Record<any, any> = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header
    });
    XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  // PDF example
  if (fileType === 'pdf') {
    const headerNames = columns.map((column) => column.columnDef.meta?.exportValue as string);
    const doc = new JsPDF();
    autoTable(doc, {
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        // halign: 'left',
        // valign: 'center',
        fontSize: 11
      }
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}
