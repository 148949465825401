import { type ModalState, useCloseIfMissingResource, useModal } from '../../hooks/useModal';
import React from 'react';
import { OpenComponentEditorDialog } from '../ComponentEditorDialog/ComponentEditorDialog';
import { snoozeComponent } from '../api';
import { OpenConfirmDialog } from '../../utils/ConfirmDialog';
import { OpenReplacementTableDialog } from '../ReplacementTableDialog/ReplacementTableDialog';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { type ComponentLifeModel } from '../component-life-types';
import { handleAxiosError } from '../../utils/http';
import { useComponentLifeData } from '../ComponentLifeDataContext';
export let OpenComponentChoiceModal: (row: ComponentLifeModel) => void = () => {};
export function ComponentChoiceModalContainer() {
  const modal = useModal<ComponentLifeModel, undefined>();
  OpenComponentChoiceModal = (r: ComponentLifeModel) => modal.open(r);
  return modal.isOpen ? <ComponentChoiceModal state={modal} /> : null;
}
export function ComponentChoiceModal({ state }: { state: ModalState<ComponentLifeModel, undefined> }) {
  const { getByComponentId, updateComponentLifeRow } = useComponentLifeData();
  const row = getByComponentId(state.param!.ComponentId);
  useCloseIfMissingResource(row, state);
  const snoozeEnabled = row?.ComponentLifeType === 'Hours';
  const onSnooze = () => {
    if (row!.ComponentLifeType === 'Hours') {
      void OpenConfirmDialog({
        title: 'Confirm Snooze',
        message: 'Are you sure you want to snooze this component for 30 days?',
        onConfirm: async () => {
          try {
            const result = await snoozeComponent(row!.ComponentId);
            updateComponentLifeRow(result);
            state.close();
          } catch (error) {
            alert(handleAxiosError(error, { serverError: 'Failed to snooze component due to a server error. Our development team has been notified.' }));
          }
        }
      });
    }
  };
  return (
    <GenericModal shrinkMode={true} open={state.isOpen} setIsOpen={state.setIsOpen} showClose={true} label='Component Actions'>
      <>
        {row && (
          <>
            <button
              className='btn-primary mt-2 mb-3 btn btn-small btn-block ml-auto text-white'
              onClick={() => {
                OpenReplacementTableDialog(row);
              }}
            >
              Add/Edit Replacement
            </button>
            <button
              className=' mt-2 mb-3 btn btn-small btn-block ml-auto text-white'
              style={{ backgroundColor: snoozeEnabled ? '#00B050' : '#808080' }}
              title={snoozeEnabled ? '' : 'Cannot snooze a scheduled component'}
              disabled={!snoozeEnabled}
              onClick={onSnooze}
            >
              Snooze
            </button>
            <button
              className='btn-warning mt-2 mb-3 btn btn-small btn-block ml-auto text-white'
              style={{ backgroundColor: '#BF9000' }}
              onClick={() => {
                void OpenComponentEditorDialog(row.AssetId, row);
              }}
            >
              Update
            </button>
            <button className='btn-warning mt-2 mb-3 btn btn-small btn-block ml-auto text-white' onClick={() => state.close()}>
              Cancel
            </button>
          </>
        )}
      </>
    </GenericModal>
  );
}
