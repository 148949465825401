import { deleteTimeTableDefinition } from '../RmxServiceTableDefinitions/DeleteTimeTableDefinition';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { useRmxServiceApi } from '../api';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import { ConfirmationDialog } from '../../utils/ConfirmationDialog/ConfirmationDialog';
import { useState } from 'react';
import type { MyTime } from '../Service.types';
import { ReactTable, type SimpleColumnDef } from '../../utils/ReactTable/ReactTable';

function useDeleteTimeTable() {
  const api = useRmxServiceApi();

  const deleteTimeColumns = deleteTimeTableDefinition();

  const deleteTimeTable = useServerDataTable({
    enableColumnFilters: true,
    showPageSizeSelector: true,
    enableGlobalSearchBar: false,
    columns: deleteTimeColumns,
    queryKey: ['delete-time-table'],
    getRows: async (params) => {
      return await api.getMyTime({ ...params, deleteTime: true });
    }
  });

  return { deleteTimeTable, api };
}

export function DeleteTimeDataTable() {
  const { deleteTimeTable, api } = useDeleteTimeTable();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MyTime>();

  const columns: SimpleColumnDef<MyTime>[] = [
    {
      accessorKey: 'type',
      header: 'Type'
    },
    {
      accessorKey: 'entryDate',
      header: 'Date',
      valueFormatter: (value: Date) => {
        const entryDate = value as Date;
        return entryDate.toLocaleDateString();
      }
    },
    {
      accessorKey: 'startTime',
      header: 'Start Time',
      valueFormatter: (value: Date) => {
        const startTime = value as Date;
        return startTime.toLocaleTimeString();
      }
    },
    {
      accessorKey: 'endTime',
      header: 'End Time',
      valueFormatter: (value: Date) => {
        const endTime = value as Date;
        return endTime.toLocaleTimeString();
      }
    }
  ];

  return (
    <div style={{ padding: '10px' }}>
      <DataTable
        table={deleteTimeTable}
        onRowClick={async (info) => {
          setSelectedRow(info);
          setOpenConfirmationDialog(true);
        }}
      />
      <ConfirmationDialog
        title='Delete Time'
        open={openConfirmationDialog}
        onAccept={async () => {
          await api.deleteNonActivityTime(selectedRow?.activityTimeCrmId!);
          await deleteTimeTable.refetch();
          setOpenConfirmationDialog(false);
        }}
        onDecline={async () => {
          setOpenConfirmationDialog(false);
        }}
        positiveText='Yes'
        prompt={
          <div>
            Are you sure you want to delete this time entry?
            {<ReactTable columns={columns} data={[selectedRow!]} />}
          </div>
        }
      />
    </div>
  );
}
