import { GenericModal } from '../../utils/GenericModal/GenericModal';
import React from 'react';
import s from '../RmxServiceParts/RmxServiceParts.module.scss';
import { PickPart } from './PickPart';
import { type Part } from '../Service.types';
import type { QueryObserverResult } from '@tanstack/react-query';
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  part: Part;
  refetch: () => Promise<QueryObserverResult<Part[], Error>>;
}
export function RmxPartPickerModal({ part, open, setOpen, refetch }: Props) {
  return (
    <GenericModal
      className={s['part-modal']}
      dialogClassName={s['part-modal-dialog']}
      contentClassName={s['part-modal-content']}
      open={open}
      setIsOpen={setOpen}
      showClose={true}
      label=' '
    >
      <PickPart part={part} refetch={refetch} setOpen={setOpen} />
    </GenericModal>
  );
}
