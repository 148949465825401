// noinspection JSUnusedGlobalSymbols
import { TimeColumnFilter } from '../TimeColumnFilter/TimeColumnFilter';
import React from 'react';
import { DateColumnFilter } from '../DateColumnFilter/DateColumnFilter';
import { NumberColumnFilter } from '../NumberColumnFilter/NumberColumnFilter';
import { TextColumnFilter } from '../TextColumnFilter/TextColumnFilter';
import type { Column, Table } from '@tanstack/react-table';
import type { TableState } from '../hooks';

interface Props<T> {
  column: Column<any, any>;
  table: Table<any>;
  tableState: TableState<T>;
}

export function ColumnFilterField<T>(props: Props<T>) {
  const columnType = props.column.columnDef.meta?.dataType ?? typeof props.table.getPreFilteredRowModel().flatRows[0]?.getValue(props.column.id);
  const dataType = props.column.columnDef.meta?.dataType;
  const serverColumnFilters = props.column.columnDef.meta?.serverFilters ?? [];
  const containsDateOnlyFilter = serverColumnFilters.includes('exact-date') || dataType === 'date';
  const containsTimeOnlyFilter = serverColumnFilters.includes('exact-time') || dataType === 'time';
  return (
    <>
      {containsTimeOnlyFilter ? (
        <TimeColumnFilter column={props.column} table={props.table} tableState={props.tableState} />
      ) : containsDateOnlyFilter ? (
        <DateColumnFilter column={props.column} table={props.table} tableState={props.tableState} />
      ) : typeof columnType === 'number' ? ( // @todo fix this and test the number filter
        <NumberColumnFilter column={props.column} table={props.table} tableState={props.tableState} />
      ) : (
        <TextColumnFilter column={props.column} table={props.table} tableState={props.tableState} />
      )}
    </>
  );
}
