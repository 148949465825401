import { componentLifeDataExists, setupComponent } from '../api';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { type ModalState, useModal } from '../../hooks/useModal';
import { DateInput } from '../../utils/DateInput';
import { useState } from 'react';
import { formatDateShort } from '../../utils';
import { type ComponentLifeModel } from '../component-life-types';
import { handleAxiosError } from '../../utils/http';
import { useComponentLifeData } from '../ComponentLifeDataContext';

type ComponentSetupDialogParam = {
  row: ComponentLifeModel;
  lastChangeDate?: Date | undefined | null;
};
export let OpenComponentSetupDialog = (row: ComponentLifeModel) => {};
export function ComponentSetupDialogComponentContainer() {
  const modal = useModal<ComponentSetupDialogParam, undefined>();
  OpenComponentSetupDialog = (row: ComponentLifeModel) => modal.open({ row });
  const key = formatDateShort(modal?.param?.lastChangeDate ?? null); // ensure the modal is unmounted and remounted when the parameter changes.
  return <>{modal.isOpen && <ComponentSetupDialogComponent key={key ?? 'undefined'} state={modal} />}</>;
}
export function ComponentSetupDialogComponent({ state }: { state: ModalState<ComponentSetupDialogParam, undefined> }) {
  const { updateComponentLifeRow } = useComponentLifeData();
  const lastChangeDate = state.param?.lastChangeDate;
  const row = state.param!.row;
  const [date, setDate] = useState<Date | null>(lastChangeDate ?? null);
  const [submitting, setSubmitting] = useState(false);
  const labelText = lastChangeDate === undefined ? 'Enter the last time this component was changed' : 'Next Change Date';

  async function submitComponentLastChanged(submittedDate: Date | null) {
    const componentId = row.ComponentId;
    const isSubmittingLastChange = lastChangeDate === undefined;
    const isSubmittingNextChangeDue = !isSubmittingLastChange;
    if (isSubmittingNextChangeDue && submittedDate === null) {
      alert('Please select a date');
      return;
    }
    if (isSubmittingLastChange && submittedDate && submittedDate >= new Date()) {
      alert('Last change date cannot be in the future');
      return;
    }

    if (isSubmittingNextChangeDue && submittedDate && submittedDate <= new Date()) {
      alert('The next change date cannot be in the past');
      return;
    }

    // If data exists then we don't need to prompt for the next change due date so there will not be an early return and the code will continue
    // to submit where it submits the last change as a value and the next change as null.
    // if the data does not exist then the modal is closed, and reopened with last change passed as the lastChangeDate or null
    // this modal will not check if data exists since it already has and submit the last change and the next change due.
    if (isSubmittingLastChange) {
      try {
        const exists = submittedDate === null ? false : await componentLifeDataExists(componentId, formatDateShort(submittedDate!));
        if (!exists) {
          state.close();
          setTimeout(() => state.open({ row, lastChangeDate: submittedDate ?? null }), 100);
          return;
        }
      } catch (err) {
        alert(handleAxiosError(err));
        return;
      }
    }
    try {
      setSubmitting(true);
      const result = await setupComponent({
        componentId,
        lastChange: isSubmittingLastChange ? formatDateShort(submittedDate) : formatDateShort(lastChangeDate),
        nextChange: isSubmittingNextChangeDue ? formatDateShort(submittedDate) : null
      });
      updateComponentLifeRow(result);
      state.close();
    } catch (error) {
      alert(handleAxiosError(error, { serverError: `Failed to update replacement date due to a server error. Our development team has been notified.` }));
    } finally {
      setSubmitting(false);
    }
  }

  async function iDontKnow() {
    setDate(null);
    await submitComponentLastChanged(null);
  }

  return (
    <GenericModal open={state.isOpen} setIsOpen={state.setIsOpen} label={'Component Setup'} showClose={true} shrinkMode={true}>
      {lastChangeDate !== undefined && (
        <div id='dialog-setup-component__edit-alert' className='flex-row align-items-center mb-3' style={{ maxWidth: 250 }}>
          We don't have enough usage information to predict when you should change your component.
          <br />
          <br />
          Please enter the date you would like to change your component.
        </div>
      )}

      <div className='form-group'>
        <label className='d-block' htmlFor='dialog-setup-component__date'>
          {labelText}
        </label>
        <DateInput tabIndex={-1} value={date} onChange={setDate} />
      </div>
      <div className='row mt-2'>
        <div className='col-6'>
          {lastChangeDate === undefined && (
            <button onClick={iDontKnow} id='dialog-setup-component__unknown_btn' className='btn-warning btn btn-small ml-auto text-white'>
              I don't know
            </button>
          )}
        </div>
        <div className='col-6 d-flex'>
          <button
            onClick={() => submitComponentLastChanged(date)}
            disabled={submitting}
            id='dialog-setup-component__update'
            className='btn-primary btn btn-small ml-auto text-white'
          >
            {submitting ? 'Updating...' : 'Submit'}
          </button>
        </div>
      </div>
    </GenericModal>
  );
}
