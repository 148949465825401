import DarkSelect from '../../shared-compat/utils/DarkSelect/DarkSelect';
import React from 'react';

export function DayFilter({
  value,
  setGlobalDateFilter
}: {
  value: number | null | string;
  setGlobalDateFilter: (setGlobalDateFilter: number | null | string) => void;
}) {
  const dayOptions = ['All', 7, 30, 60, 90].map((day) => {
    if (day === 'All') {
      return { label: 'All', value: day };
    }
    return { label: `${day}d`, value: day };
  });

  return (
    <DarkSelect
      value={value === null ? 'All' : value}
      options={dayOptions}
      onChange={(e) => {
        if (e === 'All') {
          setGlobalDateFilter(null);
        } else setGlobalDateFilter(e);
      }}
    />
  );
}
