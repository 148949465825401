import { type Activity, getAssetDetailsLabel } from '../Service.types';
import { NavLink } from 'react-bootstrap';
import React from 'react';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import { getMapUrl } from '../../utils';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { PartStatusColumnHeaderRefType } from '../../onboardings/MyWorkPartStatusOnboardingGuide';
import { humanizeDuration } from '../../utils/humaizeDuration';
import { TimeSpanInstance } from '../../utils/timespan';
import { isToday } from 'date-fns';

const helper = createServerColumnHelper<Activity>();
const ch = helper.accessor;
export const workColumnsDef = (onClickHint: () => void, partStatusActive: boolean, partStatusRef: PartStatusColumnHeaderRefType) =>
  [
    ch('startDate', 'date', {
      header: 'Start Date',
      meta: { serverFilters: ['exact-date'] },
      footer: (props) => props.column.id,
      enableSorting: true,
      sortDescFirst: false,
      enableColumnFilter: true,
      cell: (info) => {
        const startDate = info.getValue() as Date;
        const onSiteTime = info.row.original.onSiteTime as Date;
        const timeOptions: Intl.DateTimeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true // or false for 24-hour clock
        };
        const columnValue = `${startDate.toLocaleDateString()} - ${onSiteTime.toLocaleTimeString(undefined, timeOptions)}`;
        return isTodayThenBold(startDate, columnValue);
      } // formatActivityStart(info.row.original)
    }),
    ch('onSiteTime', 'date', {
      header: 'On Site Time',
      meta: {
        serverFilters: ['exact-time'],
        isVisible: false
      },
      cell: (info) => {
        const onSiteTime = info.getValue() as Date;
        const timeOptions: Intl.DateTimeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true // or false for 24-hour clock
        };
        return isTodayThenBold(info.row.original.startDate, onSiteTime.toLocaleTimeString(undefined, timeOptions));
      },
      footer: (props) => props.column.id
    }),
    ch('partStatus', 'string', {
      // @ts-ignore
      header: (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <div ref={partStatusRef}>Part Status</div>
          {partStatusActive && (
            <IconButton
              icon={faQuestionCircle}
              size='lg'
              onClick={(event) => {
                event.stopPropagation();
                onClickHint();
              }}
            />
          )}
        </div>
      ),
      enableColumnFilter: true,
      cell: (info) => {
        return isTodayThenBold(info.row.original.startDate, info.getValue());
      },
      footer: (props) => props.column.id
    }),
    ch('customerName', 'string', {
      header: 'Customer',
      footer: (props) => props.column.id,
      cell: (info) => {
        const customerName = info.row.original.customerName;
        const customerAddress = info.row.original.customerAddress;
        const mapUrl = getMapUrl(customerAddress);
        return (
          <div>
            {isTodayThenBold(info.row.original.startDate, customerName)}
            <NavLink href={mapUrl} target='_blank' style={{ padding: '0px', textDecoration: 'underline' }}>
              {customerAddress}
            </NavLink>
          </div>
        );
      }
    }),
    ch('estimatedDuration', 'string', {
      header: 'Duration',
      enableColumnFilter: true,
      cell: (info) => {
        const duration = new TimeSpanInstance(info.getValue());
        const hours = (duration.getHour() + duration.getMinute() / 60) * 60 * 60 * 1000;
        return isTodayThenBold(info.row.original.startDate, humanizeDuration({ duration: hours, tempOptions: { units: ['h', 'm'] } }));
      },
      footer: (props) => props.column.id
    }),
    // ch('confirmedContact', 'boolean', {
    //   header: 'Contact Confirmed',
    //   enableColumnFilter: true,
    //   cell: (info) => {
    //     return isTodayThenBold(info.row.original.startDate, info.getValue() ? 'Yes' : 'No');
    //   },
    //   footer: (props) => props.column.id
    // }),

    // ch('activityCrmId', 'string', {
    //   header: 'Activity Id',
    //   meta: {
    //     serverFilters: ['exact-text']
    //   },
    //
    //   cell: (info) => {
    //     return info.getValue();
    //   },
    //   footer: (props) => props.column.id,
    //   enableColumnFilter: true
    // }),
    ch('workOrderId', 'number', {
      cell: (info) => {
        return isTodayThenBold(info.row.original.startDate, info.getValue());
      },
      header: 'Work Order Id',
      meta: {
        isVisible: false
      },
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    ch('assetName', 'string', {
      header: 'Asset',
      footer: (props) => props.column.id,
      cell: (info) => {
        const row = info.row.original;
        const location = row.assetLocation ?? '';
        const description = getAssetDetailsLabel({
          id: row.assetId,
          assetCrmId: row.assetCrmId,
          crmType: row.assetCrmType,
          assetManufacturer: row.assetManufacturer,
          modelNumber: row.assetModel,
          serialNumber: row.assetSerial,
          location: row.assetLocation,
          assetName: row.assetName,
          assetGroup: row.assetGroup
        });

        if (location) {
          return (
            <div>
              {isTodayThenBold(info.row.original.startDate, description)}
              <div>
                <b>{isTodayThenBold(info.row.original.startDate, location)}</b>
              </div>
            </div>
          );
        }
        return isTodayThenBold(info.row.original.startDate, description);
      },
      enableColumnFilter: true
    }),
    ch('description', 'string', {
      header: 'Description',
      footer: (props) => props.column.id,
      cell: (info) => {
        const description = info.getValue();
        const maxLength = 100;
        const trimmedDescription = description && description.length > maxLength ? `${description.substring(0, maxLength)}...` : description;

        return isTodayThenBold(info.row.original.startDate, trimmedDescription);
      },
      enableColumnFilter: true
    }),
    ch('activityType', 'string', {
      header: 'Work Order Type',
      meta: {
        isVisible: false
      },
      footer: (props) => props.column.id
    }),
    ch('availability', 'string', {
      header: 'Activity Type',
      meta: {
        isVisible: false
      },
      footer: (props) => props.column.id
    })
  ] as const;

function isTodayThenBold(date: Date, value: string | number) {
  return isToday(date) ? <strong style={{ fontWeight: 700 }}> {value} </strong> : value;
}
