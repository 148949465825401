import { GenericModal } from '../../utils/GenericModal/GenericModal';
import s from './RmxServiceForms.module.scss';
import { Button, Card, CardBody } from 'react-bootstrap';
import React from 'react';
import type { FormSchemaModel, SubmissionInfo } from '../../form/api';

interface Props {
  latestFormSchemas: FormSchemaModel[];
  nonRequiredForms: SubmissionInfo[]; // title, FormSubmissionModel
  onFormClick: (form: FormSchemaModel) => void;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}
export function AddFormModal({ open, setIsOpen, latestFormSchemas, onFormClick, nonRequiredForms }: Props) {
  return (
    <GenericModal showClose={true} label='Add Form' className={s['modal']} contentClassName={s['modal-content']} open={open} setIsOpen={setIsOpen}>
      <Card>
        <CardBody style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxHeight: '300px', overflowY: 'auto' }}>
          {/*  Display all non required forms as buttons*/}
          {latestFormSchemas &&
            latestFormSchemas
              .filter((form) => !form.required && form.active && !nonRequiredForms?.find((non) => non.formSchemaId === form.formSchemaId))
              .map((form, index) => {
                return (
                  <Button
                    key={index}
                    style={{ display: 'block', width: '100%' }}
                    variant={'secondary'}
                    onClick={() => {
                      onFormClick(form);
                    }}
                  >
                    {form.formName.toUpperCase()}
                  </Button>
                );
              })}
        </CardBody>
      </Card>
    </GenericModal>
  );
}
