import { archiveComponent, getAssetTypeComponents, getPartVendors, upsertComponent } from '../api';
import { formatDateShort, numberOrNull, parseAspNetDateTime, stringOrNull } from '../../utils';
import clsx from 'clsx';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from '../../utils/Spinner/Spinner';
import { type ModalState, useModal } from '../../hooks/useModal';
import { DateInput } from '../../utils/DateInput';
import { CreatableDropdown } from '../../utils/CreatableDropdown/CreatableDropdown';
import { SearchableDropdown } from '../../utils/SearchableDropdown/SearchableDropdown';
import { addComponentToTable } from '../ComponentLifeComponent/ComponentLifeComponent';
import { openAddAssetTypeComponentDialog } from '../AddAssetTypeComponentDialog';
import { OpenComponentSetupDialog } from '../ComponentSetupDialog/ComponentSetupDialog';
import { type AssetTypeComponent, type ComponentLifeModel, type ComponentLifeType, type PartVendor, type UpsertComponentDto } from '../component-life-types';
import { handleAxiosError } from '../../utils/http';
import { useComponentLifeData } from '../ComponentLifeDataContext';
import { OpenConfirmDialog } from '../../utils/ConfirmDialog';

function parseIntOrNull(val: string): number | null {
  if (val.trim() === '') return null;
  const value = parseInt(val);
  if (isNaN(value)) return null;
  return value;
}

const EComponentLifeType: Record<ComponentLifeType, ComponentLifeType> = {
  Hours: 'Hours',
  Schedule: 'Schedule'
};

type ComponentEditorDialogProps = {
  newComponent: boolean;
  assetId: number;
  row: ComponentLifeModel | null;
  modalState: ModalState<ComponentEditorParams, undefined>;
};

type ComponentEditorParams = {
  newComponent: boolean;
  row: ComponentLifeModel | null;
  assetId: number;
};

export class ComponentEditorDialogState {
  static currentComponentNames: string[] = [];
}
export let OpenComponentEditorDialog: (assetId: number, row: ComponentLifeModel | null, newComponent?: boolean) => void = () => {};
export function ComponentEditorDialogContainer() {
  const modal = useModal<ComponentEditorParams, undefined>();
  OpenComponentEditorDialog = (assetId, row, newComponent) => modal.open({ assetId, row, newComponent: newComponent ?? false });
  return modal.isOpen ? (
    <ComponentEditorDialog row={modal.param!.row} assetId={modal.param!.assetId} newComponent={modal.param!.newComponent} modalState={modal} />
  ) : null;
}
export function ComponentEditorDialog({ newComponent, row, assetId, modalState }: ComponentEditorDialogProps) {
  const close = modalState.close;
  const { updateComponentLifeRow, removeComponentLifeRow } = useComponentLifeData();
  const [currentPartVendors, setCurrentPartVendors] = useState<PartVendor[]>([]);
  const [allAssetTypeComponents, setAllAssetTypeComponents] = useState<AssetTypeComponent[]>([]);
  const currentAssetTypeComponents = allAssetTypeComponents.filter((c) => !ComponentEditorDialogState.currentComponentNames.includes(c.Name));
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [scheduleStartDate, setScheduleStartDate] = useState<Date | null>(parseAspNetDateTime(row?.ScheduleStartDate));
  const [componentType, setComponentType] = useState<ComponentLifeType>(row?.ComponentLifeType || EComponentLifeType.Hours);
  const [frequency, setFrequency] = useState(row?.ScheduleIntervalMonths ?? null);
  const [quantity, setQuantity] = useState(row?.ComponentQuantity ?? null);
  const [fullLoadHours, setFullLoadHours] = useState<number | null>(row?.OverrideExpectedLifeHoursAtFullLoad ?? null);
  const [partNumber, setPartNumber] = useState(row?.ComponentPartNumber ?? null);
  const [vendorId, setVendorId] = useState(row?.ComponentVendorId ?? null);
  const [assetTypeComponentId, setAssetTypeComponentId] = useState<number | null>(null);
  const [newVendorName, setNewVendorName] = useState<string | null>(null);

  async function uploadChanges(data: UpsertComponentDto) {
    const newComponent = data.NewComponent;
    try {
      setSubmitting(true);
      const result = await upsertComponent(data);
      if (!newComponent) {
        updateComponentLifeRow(result);
        if (!result.ComponentInitialized) {
          OpenComponentSetupDialog(result);
        }
      } else {
        addComponentToTable(result);
      }
      modalState.close();
    } catch (error) {
      alert(
        handleAxiosError(error, {
          serverError: 'Failed to ' + (newComponent ? 'add component' : 'update component') + ' due to a server error. Our development team has been notified.'
        })
      );
    } finally {
      setSubmitting(false);
    }
  }

  function parseVendor() {
    // null means no vendor, 0 means insert new vendor
    // if <= 0 , reset to 0 since the dropdown has bug where it won't let you rename the new selection if the new option value is always 0.
    if (vendorId !== null && vendorId < 0) {
      return { vendorId: 0, newVendorName: vendorOptions.find((v) => v.value === vendorId)!.label };
    }
    return { vendorId, newVendorName: null };
  }

  function parseFullLoadHours() {
    if (fullLoadHours === null) return { value: null, isValid: true };
    if (isNaN(fullLoadHours) || fullLoadHours <= 0 || fullLoadHours > 1_000_000) {
      alert('Invalid full load hours');
      return { value: null, isValid: false };
    }
    return { value: fullLoadHours, isValid: true };
  }

  function parsePartNumber() {
    let pn = partNumber?.trim() ?? null;
    if (pn === '') pn = null;
    return pn;
  }
  function parseQuantity() {
    if (quantity === null) return { quantity: null, isValid: true };
    if (isNaN(quantity) || quantity <= 0 || quantity > 1_000_000) {
      alert('Invalid quantity');
      return { quantity: null, isValid: false };
    }
    return { quantity, isValid: true };
  }
  async function submitComponent(assetId: number, row: ComponentLifeModel | null, newComponent: boolean = false) {
    const componentId = row?.ComponentId || 0;
    const { quantity, isValid: quantityIsValid } = parseQuantity();
    if (!quantityIsValid) return;
    const pn = parsePartNumber();
    const { vendorId, newVendorName } = parseVendor();
    if (newComponent && !assetTypeComponentId) {
      alert('Please select a component');
      return;
    }
    const { isValid: isFullLoadHoursValid, value: fullLoadHours } = parseFullLoadHours();
    if (!isFullLoadHoursValid) {
      return;
    }

    if (row?.LastChangeDate && scheduleStartDate !== null && row.LastChangeDate >= scheduleStartDate) {
      alert('Schedule start date cannot be before or on the same date as the last time the component was replaced');
      return;
    }

    const data: UpsertComponentDto = {
      ComponentId: componentId,
      Quantity: quantity,
      PartNumber: pn,
      VendorId: vendorId,
      NewVendorName: newVendorName,
      UpdateVendor: true,
      AssetId: assetId,
      AssetTypeComponentId: assetTypeComponentId,
      NewComponent: newComponent,
      OverrideFullLoadHours: fullLoadHours,
      ScheduleStartDate: formatDateShort(scheduleStartDate),
      ScheduleIntervalMonths: frequency,
      ComponentLifeType: componentType
    };
    await uploadChanges(data);
  }

  useEffect(() => {
    (async function loadData() {
      try {
        setAllAssetTypeComponents(await getAssetTypeComponents(assetId));
        setCurrentPartVendors(await getPartVendors(assetId));
      } catch (ex) {
        alert(handleAxiosError(ex, { serverError: 'Failed to get components for this asset' }));
        close();
        throw ex;
      } finally {
        setLoading(false);
      }
    })();
  }, [assetId, close]);

  function onAddNewVendor(label: string) {
    setNewVendorName(label);
    setVendorId(-1);
  }

  const vendorOptions = currentPartVendors.map((v) => ({ label: v.Name, value: v.Id })).concat(newVendorName ? [{ label: newVendorName, value: -1 }] : []);
  const selectedVendorOption = vendorOptions.find((v) => v.value === vendorId);
  const assetTypeComponentOptions = currentAssetTypeComponents.map((c) => ({ label: c.Name, value: c.Id }));
  const currentAssetTypeComponentOption = assetTypeComponentOptions.find((c) => c.value === assetTypeComponentId);
  const currentAssetTypeComponent = useMemo(
    () => currentAssetTypeComponents.find((c) => c.Id === assetTypeComponentId),
    [currentAssetTypeComponents, assetTypeComponentId]
  );
  async function addComponentToLibrary() {
    const newComponent = await openAddAssetTypeComponentDialog(row?.AssetId ?? assetId);
    if (newComponent) {
      setAllAssetTypeComponents([...allAssetTypeComponents, newComponent]);
      setAssetTypeComponentId(newComponent.Id);
      setFullLoadHours(null);
    }
  }

  async function archive() {
    OpenConfirmDialog({
      title: 'Archive Component',
      message: 'Are you sure you want to remove this component? The replacement history will be preserved if this component is added again.',
      onConfirm: async () => {
        await archiveComponent(row!.ComponentId);
        modalState.close();
        removeComponentLifeRow(row!.ComponentId);
      }
    });
  }

  return (
    <GenericModal state={modalState} label={!row?.ComponentInitialized ? 'Setup Component' : newComponent ? 'Add Component' : 'Update Component'}>
      {loading ? (
        <Spinner />
      ) : (
        <div className={clsx('overflow-visible')} data-component-id={row?.ComponentId}>
          <div>
            {!newComponent && (
              <div className='d-flex'>
                <button onClick={archive} className='btn-danger btn btn-small ml-auto text-white'>
                  Archive Component
                </button>
              </div>
            )}
            {newComponent && (
              <div className={clsx('form-group d-flex flex-column gap-1')}>
                <label className='d-block mb-0'>Pick component from library</label>
                <SearchableDropdown
                  value={currentAssetTypeComponentOption}
                  dropdownData={assetTypeComponentOptions}
                  onSelect={(id) => {
                    setAssetTypeComponentId(id);
                    setFullLoadHours(null);
                  }}
                />
                <small>
                  Can{"'"}t find your component? You can{' '}
                  <a className='color-primary' href='#' onClick={addComponentToLibrary}>
                    Add new Component to library
                  </a>
                </small>
              </div>
            )}
            <div className='form-group'>
              <label htmlFor='dialog-component-attribute__quantity'>Quantity</label>
              <input
                className='form-control'
                type='number'
                value={quantity ?? ''}
                onChange={(e) => setQuantity(numberOrNull(e.target.value))}
                id='dialog-component-attribute__quantity'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='dialog-component-attribute__pn'>Part Number</label>
              <input
                className='form-control'
                type='text'
                value={partNumber ?? ''}
                onChange={(e) => setPartNumber(stringOrNull(e.target.value))}
                id='dialog-component-attribute__pn'
              />
            </div>
            <div className='mt-3 mb-3'>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input cl-type-radio'
                  type='radio'
                  name='cl-type-radios'
                  id='cl-hour-radio'
                  defaultValue={EComponentLifeType.Hours}
                  onClick={() => setComponentType(EComponentLifeType.Hours)}
                  defaultChecked={componentType === EComponentLifeType.Hours}
                />
                <label className='form-check-label user-select-none' htmlFor='cl-hour-radio'>
                  Dynamic
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input cl-type-radio'
                  type='radio'
                  name='cl-type-radios'
                  id='cl-schedule-radio'
                  onClick={() => setComponentType(EComponentLifeType.Schedule)}
                  defaultChecked={componentType === EComponentLifeType.Schedule}
                />
                <label className='form-check-label user-select-none' htmlFor='cl-schedule-radio'>
                  Schedule
                </label>
              </div>
            </div>
            {componentType === EComponentLifeType.Hours && (
              <div className='form-group cl-full-load-hours-section'>
                <label htmlFor='dialog-component-attribute__full-load-hours'>Full Load Hours</label>
                <input
                  className='form-control'
                  type='number'
                  value={fullLoadHours ?? ''}
                  onChange={(e) => setFullLoadHours(parseIntOrNull(e.target.value))}
                  placeholder={row?.ExpectedLifeHoursAtFullLoad?.toString() ?? currentAssetTypeComponent?.ExpectedLifeHoursAtFullLoad?.toString() ?? ''}
                  id='dialog-component-attribute__full-load-hours'
                />
                <small className='form-text'>Only add hours here if you want to override how many hours the component lasts under full load</small>
              </div>
            )}
            {componentType === EComponentLifeType.Schedule && (
              <>
                <div className='form-group cl-schedule-section'>
                  <label style={{ display: 'block' }} htmlFor='dialog-component-attribute_start_date'>
                    Starting Date
                  </label>
                  <DateInput value={scheduleStartDate} onChange={setScheduleStartDate} tabIndex={-1} id='dialog-component-attribute_start_date' />
                </div>
                <div className='form-group cl-schedule-section'>
                  <label htmlFor='dialog-component-attribute__frequency'>Frequency (Months)</label>
                  <input
                    className='form-control'
                    type='number'
                    value={frequency ?? ''}
                    onChange={(e) => setFrequency(parseIntOrNull(e.target.value))}
                    id='dialog-component-attribute__frequency'
                  />
                </div>
              </>
            )}
            <div className='form-group component-vendor-container'>
              <label className='d-block' htmlFor='dialog-component-attribute__vendor' aria-describedby='dialog-component-attribute__vendor_help'>
                Vendor
              </label>
              <CreatableDropdown
                options={vendorOptions}
                value={selectedVendorOption}
                isClearable={true}
                onNewValue={onAddNewVendor}
                onSelect={(v) => setVendorId(v)}
              />
              <small id='dialog-component-attribute__vendor_help' className='form-text'>
                Typing a vendor that is not found will add it as a new vendor.
              </small>
            </div>
            <div className='row mt-2'>
              <div className='col-6'>
                <button
                  className='btn-primary btn btn-small ml-auto text-white'
                  disabled={submitting}
                  onClick={() => submitComponent(assetId, row, newComponent)}
                >
                  {submitting ? (newComponent ? 'Submitting..' : 'Updating...') : newComponent ? 'Submit' : row!.ComponentInitialized ? 'Update' : 'Next'}
                </button>
              </div>
              <div className='col-6 d-flex'>
                <button className='btn-warning btn btn-small ml-auto text-white' onClick={() => modalState.close()} disabled={submitting}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </GenericModal>
  );
}
