import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { Alert, Form } from 'react-bootstrap';
import { CameraOrGalleryInput } from '../../utils/CameraOrGalleryInput/CameraOrGalleryInput';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import React, { useState } from 'react';
import type { ModalState } from '../../hooks/useModal';
import type { IActivityUpload } from '../api/portal-api';
import { compressImageFile } from '../../utils/image-utils';
import { useMutation } from '@tanstack/react-query';
import { ModalHoc } from '../../utils/Modal/ModalHoc';
import { useRmxServiceApi } from '../api';
import { useRmxServiceModalContext } from '../RmxServiceTabs/RmxServiceModalContext';
import { isEmpty } from 'lodash';
import { handleAxiosError } from '../../utils/http';
import { CheckBox } from '../../../components/util/form-components/CheckBox';
import { VerticalInputRow } from '../../../components/util/form-components/VerticalInputRow';

type RmxServiceUploadModalProps = {
  state: ModalState<RmxServiceUploadModalParam, any>;
  onExit: () => void;
  enabled: boolean;
};

export type RmxServiceUploadModalParam = IActivityUpload & { disableTitle: boolean; activityId: string };

export const RmxServiceUploadModal = ModalHoc(function (props: RmxServiceUploadModalProps) {
  const api = useRmxServiceApi();
  const close = props.state.close;
  const upload = props.state.param!;
  const { enabled } = props;
  const { refetchUploads } = useRmxServiceModalContext();
  const [title, setTitle] = useState(upload.title);
  const [files, setFiles] = useState<Blob[]>([]);

  const [noUploadReason, setNoUploadReason] = useState(upload.noUploadReason);

  const handleUpload = async (files: File[]) => {
    const compressedFiles = await Promise.all(files.map(async (file) => (file.type.includes('image/') ? await compressImageFile(file) : file)));
    setFiles(compressedFiles);
  };

  const {
    mutateAsync: upsertActivityUploads,
    isPending: pendingUpsertActivityUpload,
    error
  } = useMutation({
    mutationFn: async (activityUpload: IActivityUpload) => await api.upsertActivityUploads(activityUpload, files)
  });

  const handleSubmit = async () => {
    if (title === '') {
      alert('Please enter a title to add upload');
      return;
    }
    console.log('files:', files, noUploadReason, isEmpty(undefined));
    if (files.length === 0 && isEmpty(noUploadReason)) {
      alert('Please upload a file to add upload or enter a reason for not uploading');
      return;
    }

    if (files.length > 0 && !isEmpty(noUploadReason)) {
      alert('Please upload a file or enter a reason for not uploading, not both');
      return;
    }

    const updatedUpload: IActivityUpload = {
      id: upload.id,
      activityId: upload.activityId,
      title: title,
      noUploadReason: noUploadReason
    };
    await upsertActivityUploads(updatedUpload);
    await refetchUploads.refetch();
    close();
  };
  return (
    <GenericModal state={props.state} showHeader={true} showClose={true} onExit={props.onExit}>
      <Form>
        <VerticalInputRow label='Title'>
          <Form.Control type='text' placeholder='Enter Title' value={title} disabled={upload.disableTitle} onChange={(e) => setTitle(e.target.value)} />
        </VerticalInputRow>
        <VerticalInputRow label='Upload'>
          <CameraOrGalleryInput defaultFileName={upload.fileNames} setFiles={handleUpload} />
        </VerticalInputRow>
        <VerticalInputRow>
          <CheckBox
            disabled={files.length > 0}
            value={noUploadReason !== undefined}
            label='No Upload'
            onChange={() => setNoUploadReason((s) => (s === undefined ? '' : undefined))}
          />
        </VerticalInputRow>
        {noUploadReason !== undefined && (
          <VerticalInputRow label='Reason'>
            <Form.Control value={noUploadReason} onChange={(e) => setNoUploadReason(e.currentTarget.value)} placeholder='Enter Reason' as='textarea' />
          </VerticalInputRow>
        )}
        {error && <Alert variant='danger'>{handleAxiosError(error)}</Alert>}
        <LoadingButton
          variant='secondary'
          disabled={!enabled}
          loading={pendingUpsertActivityUpload || refetchUploads.isRefetching}
          onClick={handleSubmit}
          label='Submit'
        />
      </Form>
    </GenericModal>
  );
});
