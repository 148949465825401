import { GenericModal } from '../../utils/GenericModal/GenericModal';
import s from './RmxServiceForms.module.scss';
import { Spinner } from '../../utils/Spinner/Spinner';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { ModalState } from '../../hooks/useModal';
import type { FormSchemaModel, SubmissionInfo } from '../../form/api';
import { isSubmissionAndSchemaInfo } from './RmxServiceForms';
import { FormPDFExporter } from '../../form/FormRenderer/FormExporter';

type FormModalProps = {
  onExit?: () => unknown;
  modal: ModalState<FormSchemaModel | SubmissionInfo, FormSchemaModel | SubmissionInfo>;
  activityId: string;
  pdfTitle: string;
  enabled: boolean;
};

export function FormModal({ modal, activityId, pdfTitle, enabled }: FormModalProps) {
  const [loading, setLoading] = useState(true);
  const { param: currentForm, close: closeModal } = modal;
  const close = useCallback(
    (submitted?: boolean) => {
      // update the submit property of the data object after submit
      // this allows the RmxServiceForms component to know that the form has been submitted, without having to refetch the form submission
      if (submitted && isSubmissionAndSchemaInfo(currentForm)) {
        closeModal({ ...currentForm, data: { ...currentForm.data, submit: true } });
      } else {
        closeModal(currentForm);
      }
    },
    [closeModal, currentForm]
  );

  useEffect(() => {
    const eventListenerFunc = async (event: MessageEvent<{ formSubmitted: boolean }>) => event.data.formSubmitted && close(true);
    window.addEventListener('message', eventListenerFunc);
    return () => window.removeEventListener('message', eventListenerFunc);
  }, [close]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const handleIframeLoad = () => setLoading(false);
  return (
    <GenericModal showClose={true} label=' ' state={modal} dialogClassName={'model-form-dialog'} shrinkMode={true} onClose={close}>
      {loading && (
        <div className={s['iframe-loader']}>
          <Spinner />
        </div>
      )}
      {currentForm && (
        <>
          <FormPDFExporter formRef={iframeRef} formName={pdfTitle} />
          <iframe
            ref={iframeRef}
            style={{ width: '80vw', height: '70vh', display: 'flex', justifyContent: 'center', border: 'none' }}
            src={`/FormRenderer?FormSchemaId=${currentForm.formSchemaId}&FormSchemaRevisionId=${currentForm.formSchemaRevisionId}&actId=${activityId}&readOnly=${!enabled}`}
            onLoad={() => {
              handleIframeLoad();
            }}
            title='Form Viewer'
          />
        </>
      )}
    </GenericModal>
  );
}
