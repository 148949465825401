import type { Activity } from '../Service.types';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import React, { type ReactElement, useMemo, useState } from 'react';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { useModal } from '../../hooks/useModal';
import { RmxServiceActivityModal, type RmxServiceActivityModalParam } from '../RmxServiceTabs/RmxServiceActivityModal';
import { activityColumnsDef } from '../RmxServiceTableDefinitions/ActivityTableDefinition';
import { useRmxServiceApi } from '../api';
import { redirectToAsset } from '../RmxServiceTabs/redirectToAsset';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { QuoteTable } from '../QuoteTable/QuoteTable';

import { hasRole } from '../../utils';
import { DayFilter } from '../../utils/DayFilter/DayFilter';
import { MyFlagTable } from '../MyFlagTable/MyFlagTable';
import { RmxCreateFlag } from '../Flags/RmxCreateFlag';

export function useActivityTable(filterByAssetId?: number) {
  const api = useRmxServiceApi();

  const activityColumns = useMemo(() => activityColumnsDef(!filterByAssetId), [filterByAssetId]);

  const activityTable = useServerDataTable({
    queryKey: ['activities'],
    columns: activityColumns,
    getRows: async (params) =>
      await api.getActivities({
        ...params,
        assetId: filterByAssetId ?? null,
        daysFilter: serviceDateFilter,
        myWork: false,
        statusFilter: null
      }),
    initialSorting: [
      { id: 'status', desc: false },
      { id: 'startDate', desc: false },
      { id: 'onSiteTime', desc: false },
      { id: 'assignedToName', desc: false }
    ]
  });
  const activityModal = useModal<RmxServiceActivityModalParam>({
    onClose: async () => {
      await activityTable!.refetch();
    }
  });
  const [serviceDateFilter, setServiceDateFilter] = useState<number | null | string>(null);
  return { activityTable, activityModal, serviceDateFilter, setServiceDateFilter };
}

interface ActivityDataTable {
  isMyWork: boolean;
  hideEllipsisButton?: boolean;
  header?: ReactElement;
  footer?: ReactElement;
  disableEditingActivityDetails?: (row: Activity) => boolean;
  refetchFlagTable?: () => void;
  filerByAssetId?: number;
}

export function ActivityTable(props: ActivityDataTable) {
  const { activityTable, activityModal, serviceDateFilter, setServiceDateFilter } = useActivityTable(props.filerByAssetId);
  const assetQuoteHistoryModal = useModal<{ assetId: number }>();
  const assetHistoryModal = useModal<{ assetId: number }>();
  const flagHistoryModal = useModal<{ activityId: string }>();
  const createFlagModal = useModal<{ activityId?: string; customerId?: number; siteName?: string }>();
  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={activityTable}
        header={props.header ?? undefined}
        footer={props?.footer ?? <DayFilter value={serviceDateFilter} setGlobalDateFilter={setServiceDateFilter} />}
        onRowClick={(row: Activity) => {
          void activityModal.open({
            resource: row,
            disableEditingActivityDetails: props.disableEditingActivityDetails?.(row) ?? (row.status !== 'In Progress' || hasRole('Technician')),
            enableTechEditing: props.isMyWork
          });
        }}
      />
      {activityModal.isOpen && (
        <RmxServiceActivityModal
          state={activityModal}
          enableTechEditing={props.isMyWork}
          createFlag={(a) => createFlagModal.open({ activityId: a.id, customerId: a.customerId })}
          openAsset={async (a) => await redirectToAsset(a.assetId, a.assetName)}
          openAssetHistory={async (a) => {
            await assetHistoryModal.open({ assetId: a.assetId });
          }}
          openQuoteHistory={async (a) => await assetQuoteHistoryModal.open({ assetId: a.assetId })}
          openFlagHistory={async (a) => await flagHistoryModal.open({ activityId: a.id })}
          hideEllipsisButton={props.hideEllipsisButton}
        />
      )}

      <RmxCreateFlag state={createFlagModal} />
      <GenericModal state={assetQuoteHistoryModal} fullScreen={true} label={`Quote History`} showClose={true}>
        <QuoteTable filterByAssetId={assetQuoteHistoryModal.param?.assetId} />
      </GenericModal>

      <GenericModal state={assetHistoryModal} fullScreen={true} label={`Asset History`} showClose={true}>
        <ActivityTable isMyWork={false} hideEllipsisButton={true} filerByAssetId={assetHistoryModal.param?.assetId} />
      </GenericModal>

      <GenericModal fullScreen={true} state={flagHistoryModal} label={'Flag History'} showClose={true}>
        <MyFlagTable activityId={flagHistoryModal.param?.activityId} hideNewFlagButton={true} />
      </GenericModal>
    </>
  );
}
