import React, { type ReactElement } from 'react';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import { useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { myTimeColumnsDef } from '../RmxServiceTableDefinitions/MyTimeTableDefinition';
import { useRmxServiceApi } from '../api';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import s from '../RmxServiceComponent/RmxServiceComponent.module.scss';
import { RmxServiceTimePicker } from '../RmxServiceTime/RmxServiceTimePicker';
import { useModal } from '../../hooks/useModal';
import { Button } from 'react-bootstrap';
import { TimeStatsModal } from '../TimeSummary/TimeStatsModal';
import { DeleteTimeDataTable } from '../DeleteTimeDataTable/DeleteTimeDataTable';

export function UseTimeTable() {
  const api = useRmxServiceApi();

  const myTimeColumns = myTimeColumnsDef();
  const myTimeTable = useServerDataTable({
    queryKey: ['my-time'],
    columns: myTimeColumns,
    getRows: async (params) => {
      return await api.getMyTime({ ...params });
    },
    initialSorting: [
      { id: 'entryDate', desc: true },
      { id: 'startTime', desc: false }
    ]
  });

  return { myTimeTable };
}

interface TimeDataTable {
  header?: ReactElement;
}

export function TimeTable(props: TimeDataTable) {
  const { myTimeTable } = UseTimeTable();
  const nonActivityTimeModal = useModal();
  const timeStatsModal = useModal<unknown, unknown>();
  const nonActivityTimeDeleteModal = useModal();

  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={myTimeTable}
        header={
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button variant={'secondary'} onClick={() => nonActivityTimeModal.open(undefined)}>
              Add Time
            </Button>
            <Button variant={'secondary'} onClick={() => nonActivityTimeDeleteModal.open(undefined)}>
              Delete Non Activity Time
            </Button>
            <Button variant={'secondary'} onClick={() => timeStatsModal.open(undefined)}>
              Time Stats
            </Button>
            {props.header ?? undefined}
          </div>
        }
      />
      <GenericModal
        dialogClassName={s['time-modal-dialog']}
        showClose={true}
        open={nonActivityTimeModal.isOpen}
        setIsOpen={nonActivityTimeModal.setIsOpen}
        label={'Add Time'}
      >
        <RmxServiceTimePicker
          onSubmit={nonActivityTimeModal.close}
          refetchTimeTable={myTimeTable.refetch}
          options={['Select', 'Non Billable', 'INST', 'Vacation', 'Sales', 'Flex', 'Jury', 'Bereave', 'Training', 'Lunch']}
        />
      </GenericModal>
      <GenericModal
        state={nonActivityTimeDeleteModal}
        showClose={true}
        className={s['delete-time-modal']}
        dialogClassName={s['delete-time-modal-dialog']}
        bodyClassName='d-flex flex-column align overflow-hidden p-0'
        label={'Delete Time'}
      >
        <DeleteTimeDataTable />
      </GenericModal>
      <TimeStatsModal state={timeStatsModal} />
    </>
  );
}
