import { GenericModal } from '../../GenericModal/GenericModal';
import type { Activity, ContactInfo } from '../../../rmx-service/Service.types';
import React, { useRef } from 'react';
import { useContactContext } from '../ContactContext';
import { Accordion, AccordionBody, AccordionToggle, Button } from '../../../../components/util/Controls';
import { ContactFormModal } from '../ContactFormModal/ContactFormModal';
import { type ModalState, useModal } from '../../../hooks/useModal';
import s from './ContactModal.module.scss';
import { CardHeader, Spinner } from 'react-bootstrap';
import { AccordionContextConsumer } from '../../../../components/util/Controls/Accordion/AccordionContextConsumer';
import { CaretIcon } from '../../../../components/util/widgets/CaretIcon/CaretIcon';
import { ContactOnboardingGuide } from '../../../onboardings/ContactOnboardingGuide';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { SimpleColumnDef } from '../../ReactTable/ReactTable';
import { DropdownTable } from '../../DropdownTable/DropdownTable';

type Props = {
  activity: Activity;
  className?: string;
  dialogClassName?: string;
  contentClassName?: string;
  state: ModalState<undefined, undefined>;
  extraCustomerContactEllipsisItems?: { label: string; onClick: (row: ContactInfo) => void }[];
  extraWorkOrderContactEllipsisItems?: { label: string; onClick: (row: ContactInfo) => void }[];
  extraAgreementContactEllipsisItems?: { label: string; onClick: (row: ContactInfo) => void }[];
};

/**
 * Contact Modal
 * @param props
 * @constructor
 */
export function ContactModal(props: Props) {
  const { customerContacts, workOrderContacts, agreementContacts, isLoading, upsertContact, addContactToWorkOrder, removeWorkOrderContact } =
    useContactContext();
  const contactFormState = useModal<ContactInfo | undefined, undefined>();
  const [forceOpenContactOnboarding, setForceOpenContactOnboarding] = React.useState(false);

  const defaultKey =
    agreementContacts && agreementContacts.length > 0
      ? 'agreementContact'
      : workOrderContacts && workOrderContacts.length > 0
        ? 'workOrderContact'
        : 'customerContact';
  const contactModalHeaderRef = useRef<HTMLDivElement>(null);

  const contactColumns = [
    {
      header: 'ID',
      accessorKey: 'id',
      hidden: true
    },
    {
      header: 'Name',
      accessorKey: 'contactName',
      valueFormatter: (value) => {
        return <span className={s['contact-text']}>{value}</span>;
      }
    },
    {
      header: 'Email',
      accessorKey: 'contactEmail',
      valueFormatter: (value) => {
        return (
          <a className={s['contact-text']} href={`mailto:${value}`}>
            {value}
          </a>
        );
      }
    },
    {
      header: 'Phone',
      accessorKey: 'contactPhone',
      valueFormatter: (value) => {
        return (
          <a className={s['contact-text']} href={`tel:${value}`}>
            {value}
          </a>
        );
      }
    }
  ] satisfies SimpleColumnDef<ContactInfo>[];
  return (
    <>
      <GenericModal
        dialogClassName={s['contact-modal-dialog']}
        contentClassName={s['contact-modal-content']}
        showClose={true}
        open={props.state.isOpen}
        label={
          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
            <div ref={contactModalHeaderRef}>Contacts Menu</div>
            <IconButton icon={faQuestionCircle} size='lg' onClick={() => setForceOpenContactOnboarding(true)} />
          </div>
        }
        setIsOpen={props.state.setIsOpen}
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner />
          </div>
        ) : (
          <div>
            {/*Agreement Contacts*/}
            {agreementContacts && agreementContacts.length !== 0 && (
              <Accordion defaultActiveKey={defaultKey} className={'mb-4'}>
                <AccordionContextConsumer>
                  {({ activeEventKey }) => (
                    <>
                      <AccordionToggle as='span' eventKey='agreementContact' style={{ cursor: 'pointer' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', backgroundColor: '#1C1C1C' }}>
                            <CaretIcon isOpen={activeEventKey === 'agreementContact'} />
                            Agreement Contacts
                          </CardHeader>
                        </div>
                      </AccordionToggle>
                      <AccordionBody eventKey='agreementContact'>
                        <>
                          {agreementContacts && agreementContacts.length !== 0 ? (
                            <DropdownTable
                              columns={contactColumns}
                              data={agreementContacts}
                              ellipsisDropDownItems={props.extraAgreementContactEllipsisItems ? [...props.extraAgreementContactEllipsisItems] : undefined}
                            />
                          ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>No Existing Contacts Found</div>
                          )}
                        </>
                      </AccordionBody>
                    </>
                  )}
                </AccordionContextConsumer>
              </Accordion>
            )}

            {/*Work Order Contacts*/}
            {workOrderContacts ? (
              <Accordion defaultActiveKey={defaultKey} className={'mb-4'}>
                <AccordionContextConsumer>
                  {({ activeEventKey }) => (
                    <>
                      <AccordionToggle as='span' eventKey='workOrderContact' style={{ cursor: 'pointer' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', backgroundColor: '#1C1C1C' }}>
                            <CaretIcon isOpen={activeEventKey === 'workOrderContact'} />
                            Work Order Contacts
                          </CardHeader>
                        </div>
                      </AccordionToggle>

                      <AccordionBody eventKey='workOrderContact'>
                        <>
                          {workOrderContacts && workOrderContacts.length !== 0 ? (
                            <DropdownTable
                              data={workOrderContacts}
                              columns={contactColumns}
                              ellipsisDropDownItems={[
                                { label: 'Edit', onClick: async (row) => contactFormState.open(row) },
                                {
                                  label: 'Remove from work order',
                                  onClick: async (row) => row.workOrderContactId && (await removeWorkOrderContact(row.workOrderContactId))
                                },
                                ...(props.extraWorkOrderContactEllipsisItems ?? [])
                              ]}
                            />
                          ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>No Existing Contacts Found</div>
                          )}
                        </>
                      </AccordionBody>
                    </>
                  )}
                </AccordionContextConsumer>
              </Accordion>
            ) : (
              <p>No Existing Contacts Found</p>
            )}

            {/*Customer Contact*/}
            {/*<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>*/}
            {/*  <h4 style={{ color: 'white' }}>Customer Contacts</h4>*/}
            {/*</div>*/}

            {
              <Accordion defaultActiveKey={defaultKey}>
                <AccordionContextConsumer>
                  {({ activeEventKey }) => (
                    <>
                      <AccordionToggle as='span' eventKey='customerContact' style={{ cursor: 'pointer' }}>
                        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                          <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', backgroundColor: '#1C1C1C' }}>
                            <CaretIcon isOpen={activeEventKey === 'customerContact'} />
                            Customer Contacts
                          </CardHeader>
                        </div>
                      </AccordionToggle>
                      <AccordionBody eventKey='customerContact'>
                        <>
                          {customerContacts && customerContacts.length !== 0 ? (
                            <>
                              <div style={{ display: 'flex', justifyContent: 'right' }} className='mt-4'>
                                <Button size={'sm'} variant={'outline-secondary'} onClick={() => contactFormState.open(undefined)}>
                                  Add Contact
                                  {/*<FontAwesomeIcon icon={faPlusCircle} />*/}
                                </Button>
                              </div>
                              <DropdownTable
                                data={customerContacts}
                                columns={contactColumns}
                                ellipsisDropDownItems={[
                                  {
                                    label: 'Edit',
                                    onClick: async (row) => {
                                      console.log('row', row);
                                      await contactFormState.open(row);
                                    }
                                  },
                                  {
                                    label: 'Add to Work Order',
                                    onClick: async (row) => {
                                      console.log('row', row);
                                      row.id && (await addContactToWorkOrder(row.id));
                                    }
                                  },
                                  ...(props.extraCustomerContactEllipsisItems ?? [])
                                ]}
                              />
                            </>
                          ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>No Existing Contacts Found</div>
                          )}
                        </>
                      </AccordionBody>
                    </>
                  )}
                </AccordionContextConsumer>
              </Accordion>
            }
            <ContactOnboardingGuide
              forceDisplay={forceOpenContactOnboarding}
              contactModalHeaderRef={contactModalHeaderRef}
              onExit={() => setForceOpenContactOnboarding(false)}
            />

            {contactFormState.isOpen && ( // only render the model if the state is open, this allows us to set the initial state of the form
              <ContactFormModal state={contactFormState} customerContacts={customerContacts} upsertContact={upsertContact} isLoading={isLoading} />
            )}
          </div>
        )}
      </GenericModal>
    </>
  );
}
