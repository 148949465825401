import { DataTable, getRow } from '../../shared-compat/DataTable/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import React, { type MouseEvent, type ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { useModal } from '../../hooks/useModal';
import { parseComponentLifeResponse, toggleComponentLifeEnabled } from '../api';
import { addDays } from 'date-fns';
import { formatDateShort, valueOrNA } from '../../utils';
import { SearchableDropdown } from '../../utils/SearchableDropdown/SearchableDropdown';
import { ComponentEditorDialogState, OpenComponentEditorDialog } from '../ComponentEditorDialog/ComponentEditorDialog';
import { OpenComponentChoiceModal } from '../ComponentChoiceModal/ComponentChoiceModal';
import s from './ComponentLifeComponent.module.scss';
import { type ComponentLifeModel, type ComponentLifeResponse } from '../component-life-types';
import clsx from 'clsx';
import { handleAxiosError } from '../../utils/http';
import { getComponentLifeQueryKey, useSyncComponentLifeContext } from '../ComponentLifeDataContext';
import { useClientDataTable } from '../../shared-compat/DataTable/hooks';

function filterComponents(components: ComponentLifeModel[], filter: number | null) {
  return components.filter((row) => {
    const shouldHide = filter != null && (!row.NextChangeDate || row.NextChangeDate > addDays(new Date(), filter));
    return !shouldHide;
  });
}

function formatLastChange(date: Date | null) {
  if (date === null) return 'N/A';
  return formatDateShort(date);
}

function getNextChangeClassName(row: ComponentLifeModel) {
  if (row.DaysRemaining === null) return '';
  return row.DaysRemaining <= 0 ? 'text-danger' : row.DaysRemaining < 30 ? 'text-warning' : '';
}

const ch = createColumnHelper<ComponentLifeModel>();
const col = ch.accessor;
const makeColumns = (fromDashboard: boolean, openEdit: (r: ComponentLifeModel) => void) => {
  const onEditClick = (e: MouseEvent, row: ComponentLifeModel) => {
    e.preventDefault();
    openEdit(row);
  };
  return [
    ...(fromDashboard
      ? [
          col('CompanyName', {
            header: 'Company',
            cell: (i) => (
              <a target='_blank' href={i.row.original.CompanyUrl}>
                {i.getValue()}
              </a>
            )
          }),
          // convert the rest of the columns to use the above helper
          col('SiteName', {
            header: 'Site',
            cell: (i) => (
              <a target='_blank' href={i.row.original.SiteUrl}>
                {i.getValue()}
              </a>
            )
          }),
          col('AssetTypeName', { header: 'Asset Type' }),
          col('ManufacturerName', { header: 'MFG' }),
          col('ModelNumber', { header: 'Model Number' }),
          col('SerialNumber', {
            header: 'Serial Number',
            cell: (i) => (
              <a target='_blank' href={getRow(i).AssetUrl}>
                {i.getValue()}
              </a>
            )
          }),
          col('CoverageType', { header: 'Coverage Type' })
        ]
      : []),
    col('AssetExternalIntId', { header: 'CRM ID', meta: { isVisible: false } }),
    col('ComponentName', {
      header: 'Component',
      cell: (i) => (
        <a className='cl-component-name' data-component-id={getRow(i).ComponentId} onClick={(e) => onEditClick(e, getRow(i))} href='#'>
          {i.getValue<string>()} {!fromDashboard && !getRow(i).ComponentInitialized && <span style={{ color: 'red' }}> - Not Setup</span>}
        </a>
      )
    }),
    col('FormattedRemainingLifePercent', {
      header: 'Remaining Life',
      meta: { isVisible: false },
      cell: (i) => (
        <span className='cl-remaining-life' data-component-id={getRow(i).ComponentId}>
          {i.getValue()}
        </span>
      )
    }),
    col('LastChangeDate', {
      header: 'Last Change',
      meta: { isVisible: false },
      cell: (i) => (
        <a className='cl-last-change p-5' data-component-id={getRow(i).ComponentId} href='#'>
          {formatLastChange(i.getValue())}
        </a>
      )
    }),
    col('DaysRemaining', {
      header: 'Days Remaining',
      cell: (i) => {
        const value = i.getValue();
        return (
          <span className={`p-5 cl-days-remaining ${getNextChangeClassName(getRow(i))}`} data-component-id={getRow(i).ComponentId}>
            {value !== null && value > 60 ? 'Ok' : valueOrNA(value)}
          </span>
        );
      }
    }),
    col('ComponentPartNumber', {
      header: 'PN',
      meta: { isVisible: !fromDashboard },
      cell: (i) => (
        <span className='cl-part-number p-5' data-component-id={getRow(i).ComponentId}>
          {valueOrNA(i.getValue<string>())}
        </span>
      )
    }),
    col('ComponentQuantity', {
      header: 'Qty',
      meta: { isVisible: !fromDashboard },
      cell: (i) => (
        <span className='cl-quantity p-5' data-component-id={getRow(i).ComponentId}>
          {valueOrNA(i.getValue<number>())}
        </span>
      )
    }),
    ...(!fromDashboard
      ? [
          col('ComponentVendorName', {
            header: 'Vendor',
            meta: { isVisible: false },
            cell: (i) => (
              <span className='cl-vendor p-5' data-component-id={getRow(i).ComponentId}>
                {i.getValue() == null ? 'N/A' : i.getValue<string>()}
              </span>
            )
          })
        ]
      : []),
    col('ExpectedLifeHoursAtFullLoad', {
      header: 'Expected Full Load Hours',
      meta: { isVisible: false }
    }),
    col('FullLoadHoursSinceLastReset', {
      header: 'Current Full Load Hours',
      meta: { isVisible: false }
    })
  ];
};

type ComponentLifeTableProps = {
  data: ComponentLifeModel[];
  fromDashboard: boolean;
};
const filterOptions = [null, 30, 60].map((v) => ({ value: v, label: v == null ? 'All' : `${v}d` }));

export function ComponentLifeTable({ data, fromDashboard }: ComponentLifeTableProps) {
  const openEdit = useCallback((row: ComponentLifeModel) => {
    if (!row.ComponentInitialized) {
      void OpenComponentEditorDialog(row.AssetId, row);
    } else {
      OpenComponentChoiceModal(row);
    }
  }, []);
  const columns = useMemo(() => makeColumns(fromDashboard, openEdit), [fromDashboard, openEdit]);
  const [dayFilter, setDayFilter] = useState<number | null>(30);
  const filteredData = useMemo(() => (fromDashboard ? filterComponents(data, dayFilter) : data), [fromDashboard, data, dayFilter]);
  const table = useClientDataTable({ columns, data: filteredData, initialPageSize: 30 });
  return (
    <>
      {fromDashboard && (
        <div className='form-group' style={{ maxWidth: 150 }}>
          <label htmlFor='component-life-filter-drop-down'>Filter</label>
          <SearchableDropdown dropdownData={filterOptions} value={filterOptions.find((o) => o.value === dayFilter)} onSelect={setDayFilter} />
        </div>
      )}
      <DataTable table={table} />
    </>
  );
}

type ComponentLifeComponentProps = {
  dataRequester: () => Promise<ComponentLifeResponse>;
  isVisible?: boolean;
  fromDashboard: boolean;
  assetId: number;
};

export let openComponentLifeModal = () => {};
window.openComponentLifeModal = () => openComponentLifeModal();

export function ComponentLifeComponent({ dataRequester, fromDashboard, assetId, isVisible: isVisibleProp }: ComponentLifeComponentProps) {
  const modalState = useModal();
  openComponentLifeModal = () => modalState.setIsOpen(true);

  const isVisible = useMemo(() => (fromDashboard ? isVisibleProp : modalState.isOpen), [fromDashboard, isVisibleProp, modalState.isOpen]);
  const queryClient = useQueryClient();
  const queryKey = getComponentLifeQueryKey(assetId);
  const {
    isPending,
    error,
    data: responseData
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => parseComponentLifeResponse(await dataRequester()),
    enabled: isVisible
  });
  const data = useSyncComponentLifeContext(responseData, queryClient, queryKey);
  const toggleComponentLifeEnabledMutation = useMutation({
    mutationFn: (id: number) => toggleComponentLifeEnabled(id),
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);
    }
  });

  const toggleActivated = async () => {
    try {
      const result = await toggleComponentLifeEnabledMutation.mutateAsync(data!.id);
      const container = document.querySelector('.component-life-container');
      if (container) {
        if (result.ComponentLifeEnabled) {
          container.classList.remove('c-disabled');
          container.classList.add('c-enabled');
        } else {
          container.classList.add('c-disabled');
          container.classList.remove('c-enabled');
        }
      }
    } catch (err) {
      alert(handleAxiosError(err));
    }
  };

  addComponentToTable = (row: ComponentLifeModel) => {
    queryClient.setQueryData(queryKey, {
      ...data,
      Components: [...data!.Components, row]
    });
  };
  const components = data?.Components;
  // update the new component editor dialog
  useEffect(() => {
    if (!fromDashboard && components) {
      ComponentEditorDialogState.currentComponentNames = components.map((c) => c.ComponentName);
    }
  }, [components, fromDashboard]);

  function addComponent() {
    OpenComponentEditorDialog(data!.id, null, true);
  }

  const isLoading = isPending || toggleComponentLifeEnabledMutation.isPending;
  const isError = !!error || !!toggleComponentLifeEnabledMutation.error;
  const loadingState = { isLoading, isError };
  return (
    <>
      {!isVisible ? null : fromDashboard ? (
        <LoadingLayout {...loadingState}>{() => <ComponentLifeTable data={components!} fromDashboard={fromDashboard} />}</LoadingLayout>
      ) : (
        modalState.isOpen && (
          <GenericModal
            dialogClassName={s['modal']}
            contentClassName={s['modal-content']}
            open={modalState.isOpen}
            setIsOpen={modalState.setIsOpen}
            label={(data?.name ?? '') + ' Component Life'}
            showClose={true}
          >
            <LoadingLayout {...loadingState}>
              {() => (
                <>
                  {!data!.ComponentLifeEnabled && (
                    <div className={clsx('mb-2 mt-2 d-flex justify-content-center align-items-center')}>
                      <button onClick={toggleActivated} id='component-life__activate-btn' className='btn btn-primary'>
                        Activate
                      </button>
                    </div>
                  )}
                  {data!.ComponentLifeEnabled && (
                    <>
                      <div className='gap-2 d-flex justify-content-end mb-2'>
                        <button onClick={addComponent} id='component-life__add-component-btn' className='btn btn-primary text-white'>
                          Add Component
                        </button>
                        <button onClick={toggleActivated} id='component-life__deactivate-btn' className='btn btn-warning text-white'>
                          Deactivate
                        </button>
                      </div>
                      <ComponentLifeTable data={components!} fromDashboard={fromDashboard} />
                      <div className='d-flex mt-3'>
                        <button
                          onClick={() => modalState.close()}
                          id='dialog-component-life__close'
                          style={{ backgroundColor: 'darkgrey' }}
                          className='btn btn-small ml-auto text-white'
                        >
                          Done
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
            </LoadingLayout>
          </GenericModal>
        )
      )}
    </>
  );
}

function LoadingLayout({ isLoading, isError, children }: { isLoading: boolean; isError: boolean; children: () => ReactElement }) {
  if (isLoading) return <Spinner style={{ display: 'block', margin: 'auto' }} />;
  if (isError)
    return (
      <span>An Error occurred while fetching data. Make sure you are on the internet and try again. If the issue keeps happening, please contact support.</span>
    );
  return children();
}

export let addComponentToTable = (_: ComponentLifeModel) => {};
