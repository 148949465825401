import { createRmxAxios } from '../utils/ajax';

const api = createRmxAxios();

export type FormSchemaRevisionModel = Omit<FormSchemaModel, 'required'>;

export type BaseFormInfo = {
  formSchemaId: number;
  formName: string;
  formSchemaRevisionId: number;
  required: boolean;
  active: boolean;
};

export type FormSchemaModel = BaseFormInfo & {
  schema: string;
  dealerId: string;
  version: number;
};

export type SubmissionInfo = BaseFormInfo & {
  id: number | null;
  data: { submit: boolean } | null;
};

export type ExistingSubmissionAndSchemaInfo = SubmissionInfo & {
  id: number; // Overriding the `id` from `BaseSubmissionAndSchemaInfo` to remove `null` as an option
};

export type SubmissionInfoResponse = BaseFormInfo & {
  id: number | null;
  data: string;
};

export function formatFormSubmissionResponse(res: SubmissionInfoResponse) {
  return {
    ...res,
    data: res.data !== null ? (JSON.parse(res.data) as { submit: boolean }) : null
  } satisfies SubmissionInfo;
}

export interface GetLatestFormSchemaRequest {
  formSchemaId: number;
  formSchemaRevisionId: number | null;
}

export async function getLatestFormSchema(req: GetLatestFormSchemaRequest) {
  return await api.post<FormSchemaModel | FormSchemaRevisionModel>(`/FormSchema/`, req).then((response) => {
    return response.data;
  });
}

//TODO - This needs to be updated to get formSchemas depending on the dealer
export async function getLatestFormSchemas() {
  return await api.get<FormSchemaModel[]>(`/FormSchema/`).then((response) => {
    return response.data;
  });
}

export interface GetFormSubmissionRequest {
  activityId: string;
  formSchemaId: number;
  formSchemaRevisionId?: number;
}

export interface GetFormSubmissionResponse {
  schema: string;
  data?: string;
}

export async function getFormSubmissionAndSchema(req: GetFormSubmissionRequest): Promise<GetFormSubmissionResponse> {
  return (await api.post<{ schema: string; data?: string }>(`/FormSubmission`, req)).data;
}

export async function GetSubmissionAndSchemaInfoByActivityId(activityId: string): Promise<SubmissionInfo[]> {
  const data = (await api.get<SubmissionInfoResponse[]>(`/FormSubmission/GetSubmissionAndSchemaInfo/${activityId}`)).data;
  return data.map((res) => formatFormSubmissionResponse(res));
}

export interface UpsertFormSubmissionRequest {
  formSchemaId: number;
  formSchemaRevisionId?: number;
  activityId: string;
  data: string;
}

export async function upsertFormSubmission(req: UpsertFormSubmissionRequest, signal?: AbortSignal) {
  return (await api.post<unknown>(`/FormSubmission/UpsertFormSubmission`, req, { signal })).data;
}

export interface SaveFormSchemaRequest {
  id: number;
  formName: string;
  schema: string;
  dealerId: number;
}

export async function createOrReviseForm(req: SaveFormSchemaRequest) {
  return await api.post('/FormSchema/CreateOrReviseForm', req);
}

export async function deleteFormSubmission(submissionId: number) {
  return await api.delete(`/FormSubmission/${submissionId}`);
}
