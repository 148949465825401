import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import type { MyTime } from '../Service.types';

const helper = createServerColumnHelper<MyTime>();
const col = helper.accessor;
export const deleteTimeTableDefinition = () => [
  col('type', 'string', {
    header: 'Type',
    cell: (info) => info.getValue(),
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('entryDate', 'date', {
    meta: { serverFilters: ['date-range'] },
    header: 'Date',
    cell: (info) => {
      const entryDate = info.getValue();
      return entryDate.toLocaleDateString();
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('startTime', 'time', {
    header: 'Start Time',
    cell: (info) => {
      const startTime = info.getValue();
      return startTime.toLocaleTimeString();
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  }),
  col('endTime', 'time', {
    header: 'End Time',
    cell: (info) => {
      const endTime = info.getValue();
      return endTime.toLocaleTimeString();
    },
    footer: (props) => props.column.id,
    enableColumnFilter: true
  })
];
